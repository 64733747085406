import React from 'react'

const SuccessButton = ({ title, onClick, type, customclass }) => {
  return (
    <button
      className={`SuccessButton px-5 py-2  ${customclass}`}
      type={type}
      onClick={onClick}
    >
      {title}
    </button>
  );
};

export default SuccessButton
