import React from "react";
import { Route } from "react-router-dom";
import { Empty } from "../../App";
const ObsoleteBlogs = () => {
  return (
    <>
      <Route
        exact
        path="/blog/SecurePass-One-Stop-Solution-For-Complete-Access-Security-Solutions-Employees-Management-and-Safety"
        component={Empty}
      />
      <Route
        exact
        path="/blog/five-reasons-to-choose-modern-and-contactless-access-control-system"
        component={Empty}
      />
      <Route
        exact
        path="/blog/mandatory-access-control-system-use-advantages-and-disadvanteges"
        component={Empty}
      />
      <Route
        exact
        path="/blog/discretionary-access-control-uses-advantages-disadvanteges-and-more"
        component={Empty}
      />
      <Route exact path="/blog/role-based-access-control-management-system" component={Empty} />
      <Route
        exact
        path="/blog/Types-of-Access-Control-Systems-Management-Pros-and-Cons"
        component={Empty}
      />
      <Route
        exact
        path="/blog/5-Crucial-Advantages-of-Contactless-and-Advanced-Access-Control-Solutions-in-the-Modern-World"
        component={Empty}
      />
      <Route
        exact
        path="/blog/choose-the-right-visitor-management-system-for-your-office-look-for-these-5-key-aspects"
        component={Empty}
      />
      <Route
        exact
        path="/blog/ensure-safe-return-to-office-for-employees-by-following-these-5-steps"
        component={Empty}
      />
      <Route exact path="/blog/smart-card-readers-what-do-they-do-how-do-they-work" component={Empty} />
      <Route exact path="/blog/five-benefits-of-cloud-access-management-system" component={Empty} />
      <Route
        exact
        path="/blog/contactless-access-and-safety-for-educational-institutions"
        component={Empty}
      />
      <Route
        exact
        path="/blog/contactless-access-and-safety-for-durga-pooja-celebration"
        component={Empty}
      />
      <Route exact path="/blog/access-management-system-for-coworking-spaces" component={Empty} />
      <Route exact path="/blog/social-distancing-retail-store" component={Empty} />
      <Route exact path="/blog/govt-guidelines-for-social-distancing" component={Empty} />
      <Route
        exact
        path="/blog/wiegand-card-reader-in-access-control-uses-and-safety-in-access-control-systems"
        component={Empty}
      />
      <Route
        exact
        path="/blog/magnetic-stripe-card-reader-usage-types-advantage-access-control-systems-and-more"
        component={Empty}
      />
      <Route
        exact
        path="/blog/proximity-card-reader-working-usage-access-control-pros-and-cons"
        component={Empty}
      />
      <Route exact path="/blog/biometric-scanner-types-and-usage-in-access-control" component={Empty} />
      <Route exact path="/blog/barcode-readers-types-and-usage" component={Empty} />
      <Route
        exact
        path="/blog/card-based-access-control-types-of-cards-evolution-of-access-control"
        component={Empty}
      />
      <Route
        exact
        path="/blog/evolution-of-access-control-systems-PIN-as-earliest-technology-intervention"
        component={Empty}
      />
      <Route exact path="/blog/access-management-system-for-restaurants" component={Empty} />
      <Route exact path="/blog/access-management-system-for-retail-stores" component={Empty} />
      <Route exact path="/blog/access-management-system-for-sme-or-startup" component={Empty} />
      <Route exact path="/blog/access-management-system-for-hospitals" component={Empty} />
      <Route exact path="/blog/visitor-management-checklist" component={Empty} />
      <Route exact path="/blog/apartment-safety-during-covid" component={Empty} />
      <Route
        exact
        path="/blog/govt-rules-guidelines-for-social-distancing-retailers"
        component={Empty}
      />
      <Route exact path="FAQs" component={Empty} />
      <Route exact path="privacy" component={Empty} />
    </>
  );
};

export default ObsoleteBlogs;
