import React, { useState, useEffect } from "react";
import SuccessButton from "./SuccessButton";
import { PageForm, addCampaignParams } from "../utils/config";
import Loading from "./Loading";
import { Redirect } from "react-router-dom";

const KnowMoreForm = ({ queryParams, setEmailUID, mountedOn, locationSearch }) => {
  const [formData, setFormData] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const handleChange = (e) => {
    if (mobileError) {
      setMobileError(false);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setEmailUID(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submit`;

    if (`${formData.phone}`.length !== 10) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setLoading(true);
      setMobileError(false);
      let body = {
        data: {
          ...formData,
          PageForm: PageForm + " contact form",
          sendMail: true,
        },
      };

      body = addCampaignParams(queryParams, body);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setResult("Request Submitted");
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setResult(null);
    }, 4000);
  }, [result]);

  return (
    <div className="row pt-5 mt-5 d-flex justify-content-center align-items-center h-100">
      <div className="col-8">
        <div className="row">
          <div className="col-12">
            <div className="container">
              {result === "Request Submitted" ? (
                <Redirect
                  to={"/download-casestudy-thankyou" + locationSearch}
                />
              ) : null}
              <div className="col-12 my-5">
                <p className="contactTitle">Download Case Studies</p>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
                    <input
                      required
                      name="firstName"
                      onChange={handleChange}
                      className="px-3  smallInput"
                      placeholder="First Name*"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
                    <input
                      required
                      name="lastName"
                      onChange={handleChange}
                      className="px-3 smallInput"
                      placeholder="Last Name*"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
                    <input
                      required
                      name="email"
                      className="px-3 smallInput"
                      onChange={handleChange}
                      placeholder="Email*"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
                    <input
                      required
                      name="phone"
                      type="number"
                      className="px-3 smallInput"
                      onChange={handleChange}
                      placeholder="Phone*"
                    />
                    {mobileError ? (
                      <p className="w-100 text-center">
                        <small className="text-danger font-weight-bold">
                          {mobileError}
                        </small>
                      </p>
                    ) : null}
                  </div>
                  {mountedOn === "taboola-ads-campaign" ? null : (
                    <div className="col-12 m-0 p-2">
                      <textarea
                        required
                        className="px-3 smallInput"
                        name="message"
                        style={{ height: "8rem" }}
                        onChange={handleChange}
                        placeholder="Please explain your requirements in few words..."
                      />
                    </div>
                  )}
                  <div className="col-12">{loading ? <Loading /> : null}</div>
                  <div className="col-12">
                    {result ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: "#42C588",
                        }}
                        className="my-2"
                      >
                        {result}
                      </p>
                    ) : null}
                  </div>
                  <div className="col-12  d-flex justify-content-center align-items-center my-3 m-0 p-2">
                    <SuccessButton type="submit" title="Submit" />
                  </div>
                </div>
              </form>
            </div>
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default KnowMoreForm;
