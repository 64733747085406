import { cloudFrontOrigin } from "./utils/config";

export const metaTags = {
  home: {
    metaTitle: "Contactless Door Access Control System | SecurePass",
    metaDescription:
      "SecurePass is a contactless door access control system that lets you manage access to your property from a cloud-based centralised location.",
    url: "/",
    ogTitle: "SecurePass: Contactless Access Control System",
    ogDescription:
      "SecurePass is a modern touchless access control system for your facility with built-in temperature scanner and a comprehensive visitor management system.",
    ogImage: `${cloudFrontOrigin}mac-phone-access-control_to4uev.png`,
  },
  faq: {
    metaTitle: "FAQ | SecurePass - Access Control Solution",
    metaDescription:
      "Frequently Asked Questions about contactless access control, biometric access control, installation & more",
    url: "/faqs",
    ogTitle: "FAQ | SecurePass - Access Control Solution",
    ogDescription: "",
    ogImage: `${cloudFrontOrigin}mac-phone-access-control_to4uev.png`,
  },
  pricing: {
    metaTitle: "Pricing | SecurePass",
    metaDescription:
      "Pricing of SecurePass Access Control System With/Without Temperature Scanner Including Hardware, Accessories & Dashboard.",
    url: "/pricing",
    ogTitle: "Pricing Of SecurePass With/Without Temperature Scanner",
    ogDescription:
      "Pricing of SecurePass Access Control System With/Without Temperature Scanner Including Hardware, Accessories & Dashboard.",
    ogImage: `${cloudFrontOrigin}mac-phone-access-control_to4uev.png`,
  },
  downloadCaseStudyThankYou: { ogTitle: "", ogDescription: "", ogImage: "" },
  subscriptionThankYou: { ogTitle: "", ogDescription: "", ogImage: "" },
  getAQuoteThankYou: { ogTitle: "", ogDescription: "", ogImage: "" },
  contactThankYou: { ogTitle: "", ogDescription: "", ogImage: "" },
  getAquote: {
    metaTitle: "Get a Quote | SecurePass",
    metaDescription:
      "Get a quotation for enabling your premises with a full-fledged cloud-based access control system that lets you manage access to your property from a centralized location using the dashboard and app",
    url: "/getaquote",
    ogTitle: "Get A Quote | SecurePass",
    ogDescription:
      "Please explain your requirements to get a quote for SecurePass Contactless Access Control System",
    ogImage: `${cloudFrontOrigin}sp_black_logo.svg`,
  },
  blogs: {
    metaTitle: "SecurePass Blog - Access Control System for Office",
    metaDescription:
      "Learn more about contactless access control solutions on the SecurePass Blogs covering the latest updates on the industry. ",
    url: "/blogs",
    ogTitle: "SecurePass Blogs",
    ogDescription: "Know more about contactless door access control systems and their importance.",
    ogImage: `${cloudFrontOrigin}sp_black_logo.svg`,
  },
  privacyPolicy: {
    metaTitle: "Privacy Policy | SecurePass",
    metaDescription:
      "The Personal Information that we collect are used for providing and improving the Service.",
    url: "/privacy-policy",
    ogTitle: "Privacy Policy | SecurePass",
    ogDescription: "Privacy Policy of Securepass & Singularity Automation Pvt. Ltd.",
    ogImage: `${cloudFrontOrigin}sp_black_logo.svg`,
  },
  dealerPartner: {
    metaTitle: "",
    metaDescription: "",
    url: "/dealer-partner",
    ogTitle: "Become A Dealer Partner With SecurePass ",
    ogDescription: "Join SecurePass As A Dealer Partner And Grow With Us",
    ogImage: `${cloudFrontOrigin}sp_black_logo.svg`,
  },
  visitorManagementSystem: {
    metaTitle: "",
    metaDescription: "",
    url: "/visitor-management-system",
    ogTitle: "Contactless Visitor Management System | SecurePass",
    ogDescription: "Ensures touchless entry for visitors by making your entrance COVID-19 ready",
    ogImage: `${cloudFrontOrigin}mac-phone-access-control_to4uev.png`,
  },
};
