import React from "react";
import { Carousel } from "react-responsive-carousel";
import { cloudFrontOrigin } from "../utils/config";

const TestimonialCarausel = () => {
  return (
    <Carousel
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      //   style={{ backgroundColor: "white" }}
    >
      <div className="row h-100">
        <div
          className="col-md-8 col-lg-7 col-xs-12 col-sm-12 mx-auto  text-center carausel-slide-bg"
          style={{
            padding: "3rem 3rem",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center py-2 ">
                <img
                  src={`${cloudFrontOrigin}colive-logo.svg`}
                  style={{ maxWidth: "250px" }}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 py-2 d-flex align-items-center">
                <div>
                  <p className="text-left benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
                    We worked with Singularity for the last few years and been very happy with them in
                    term of our progress in terms of our relations. We use their products all the time.
                  </p>
                  <h6 className="text-left benifit-text-lp" style={{ fontWeight: "800" }}>
                    Suresh Rangarajan ,
                    <br />
                    Founder & CEO- COLIVE
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row h-100">
        <div
          className="col-md-8 col-lg-7 col-xs-12 col-sm-12 mx-auto text-center carausel-slide-bg"
          style={{
            padding: "3rem 3rem",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center py-2">
                <img
                  src={`${cloudFrontOrigin}safestorage-logo.svg`}
                  style={{ maxWidth: "250px" }}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 py-2 d-flex align-items-center">
                <div>
                  <p className="text-left benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
                    With SecurePass we can now grant access to our staff for a given duration remotely
                    from a dashboard or mobile. We now feel secure to scale our operations without
                    worrying about security & costs.
                  </p>
                  <h6 className="text-left benifit-text-lp" style={{ fontWeight: "800" }}>
                    Nageshwar Midan,
                    <br />
                    Co-Founder, Safe Storage 
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row h-100">
        <div
          className="col-md-8 col-lg-7 col-xs-12 col-sm-12 mx-auto text-center carausel-slide-bg"
          style={{
            padding: "3rem 3rem",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center py-2">
                <img
                  src={`${cloudFrontOrigin}vfitness-logo.svg`}
                  style={{ maxWidth: "250px" }}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 py-2 d-flex align-items-center">
                <div>
                  <p className="text-left benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
                    SecurePass was the one option I came across that offered all the options I was
                    looking out for. So I am really grateful & thankful to the SecurePass team for
                    reaching out to me and helping me understand the entire system.
                  </p>
                  <h6 className="text-left benifit-text-lp" style={{ fontWeight: "800" }}>
                    John,
                    <br />
                    Founder- VFITNESS
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row h-100">
        <div
          className="col-md-8 col-lg-7 col-xs-12 col-sm-12 mx-auto text-center carausel-slide-bg"
          style={{
            padding: "3rem 3rem",
            backgroundColor: "rgba(255, 255, 255, 1)",
          }}
        >
          <div>
            <div className="row">
              <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center py-2">
                <img
                  src={`${cloudFrontOrigin}white.svg`}
                  style={{ maxWidth: "250px" }}
                  alt=""
                  srcset=""
                />
              </div>
              <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 py-2 d-flex align-items-center">
                <div>
                  <p className="text-left benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
                    When we installed the SecurePass contactless access control system, we thought we
                    have found the best system that is available in the market & the reaction of other
                    residents was also fantastic.
                  </p>
                  <h6 className="text-left benifit-text-lp" style={{ fontWeight: "800" }}>
                    Bansi Gersappa,
                    <br />
                    Society President- Alsa Habitat
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default TestimonialCarausel;
