import React, { useState, useEffect } from "react";
import { PageForm, addCampaignParams } from "../utils/config";
import { Redirect } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import GetaQuoteButton from "../components/GetaQuoteButton";
import SuccessButton from "../components/SuccessButton";
import Loading from "../components/Loading";
const ConnectPlusCampaignForm = ({
  queryParams,
  setEmailUID,
  mountedOn,
  locationSearch,
}) => {
  const [formData, setFormData] = useState({ feature: {} });
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const handleChange = (e) => {
    if (mobileError) {
      setMobileError(false);
    }
    if (e.target.name.startsWith("feature")) {
      setFormData({
        ...formData,
        feature: {
          ...formData.feature,
          [e.target.value]: !formData.feature[e.target.value],
        },
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "email") {
      setEmailUID(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submitConnectPlusLead`;

    if (`${formData.phone}`.length !== 10) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setLoading(true);
      setMobileError(false);
      let features = Object.entries(formData.feature)
        .filter((v) => v[1])
        .map((v) => v[0])
        .join(" | ");
      console.log(features);

      let body = {
        data: {
          ...formData,
          feature: features,
          PageForm: PageForm + " contact form",
          sendMail: true,
        },
      };

      body = addCampaignParams(queryParams, body);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setLoading(false);
          setResult("Request Submitted");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setResult(null);
    }, 4000);
  }, [result]);

  return (
    <div className="container " style={{ paddingTop: "10vh" }}>
      {result === "Request Submitted" ? (
        <Redirect to={"/download-casestudy-thankyou" + locationSearch} />
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 text-center">
            <h5
              style={{ color: "#e53d54" }}
              className="my-4 text-center font-weight-bold"
            >
              Let Us Call You
            </h5>
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="firstName"
              onChange={handleChange}
              className="px-3  smallInput"
              placeholder="First Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="lastName"
              onChange={handleChange}
              type="text"
              className="px-3 smallInput"
              placeholder="Last Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="email"
              type="email"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Email*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="phone"
              type="number"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Phone*"
            />
            {mobileError ? (
              <p className="w-100 text-center">
                <small className="text-danger font-weight-bold">
                  {mobileError}
                </small>
              </p>
            ) : null}
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <textarea
              required
              name="message"
              type="text"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Message*"
            />
          </div>

          <div className="col-12">{loading ? <Loading /> : null}</div>
          <div className="col-12">
            {result ? (
              <p
                style={{
                  textAlign: "center",
                  color: "#42C588",
                }}
                className="my-2"
              >
                {result}
              </p>
            ) : null}
          </div>
          {/* {mountedOn === "taboola-ads-campaign" ? (
            <div className="col-12 d-flex justify-content-center align-items-center my-2 m-0">
              <h6 className="">Try for Free for 6 Months.</h6>
            </div>
          ) : null} */}
          <div className="col-12  d-flex justify-content-center align-items-center my-3 m-0 p-2 mb-3">
            <GetaQuoteButton type="submit" title="Submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ConnectPlusCampaignForm;
