import React, { useState, useEffect } from "react";
import SuccessButton from "./SuccessButton";
import { PageForm, addCampaignParams } from "../utils/config";
import Loading from "./Loading";
import { Redirect } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import GetaQuoteButton from "./GetaQuoteButton";
const ContactForm = ({ queryParams, setEmailUID, mountedOn, locationSearch }) => {
  const [formData, setFormData] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const handleChange = (e) => {
    if (mobileError) {
      setMobileError(false);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      setEmailUID(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submit`;

    if (`${formData.phone}`.length !== 10) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setLoading(true);
      setMobileError(false);
      let body = {
        data: {
          ...formData,
          PageForm: PageForm + " contact form",
          sendMail: true,
        },
      };

      body = addCampaignParams(queryParams, body);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          window._tfa.push({ notify: "event", name: "lead", id: 1358300 });
          setLoading(false);
          setResult("Request Submitted");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setResult(null);
    }, 4000);
  }, [result]);

  return (
    <div className="container">
      {result === "Request Submitted" ? (
        mountedOn === "Adcounty-Casestudy" ||
        mountedOn === "taboola-ads-campaign" ? (
          <Redirect to={"/thankyou" + locationSearch} />
        ) : (
          <Redirect to={"/contact-thankyou" + locationSearch} />
        )
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row">
          {mountedOn === "taboola-ads-campaign" ||
          mountedOn === "Adcounty-Casestudy" ? (
            <div className="col-12 text-center">
              <h5
                style={{ color: "#e53d54" }}
                className="my-4 text-center font-weight-bold"
              >
                Price Starts At Rs. 849/- Per Month
              </h5>
            </div>
          ) : null}

          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="firstName"
              onChange={handleChange}
              className="px-3  smallInput"
              placeholder="First Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="lastName"
              onChange={handleChange}
              className="px-3 smallInput"
              placeholder="Last Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="email"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Email*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="phone"
              type="number"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Phone*"
            />
            {mobileError ? (
              <p className="w-100 text-center">
                <small className="text-danger font-weight-bold">
                  {mobileError}
                </small>
              </p>
            ) : null}
          </div>
          {mountedOn === "taboola-ads-campaign" ? null : (
            <div className="col-12 m-0 p-2">
              <textarea
                required
                className="px-3 smallInput"
                name="message"
                style={{ height: "8rem" }}
                onChange={handleChange}
                placeholder="Please explain your requirements in few words..."
              />
            </div>
          )}
          <div className="col-12">{loading ? <Loading /> : null}</div>
          <div className="col-12">
            {result ? (
              <p
                style={{
                  textAlign: "center",
                  color: "#42C588",
                }}
                className="my-2"
              >
                {result}
              </p>
            ) : null}
          </div>
          {/* {mountedOn === "taboola-ads-campaign" ? (
            <div className="col-12 d-flex justify-content-center align-items-center my-2 m-0">
              <h6 className="">Try for Free for 6 Months.</h6>
            </div>
          ) : null} */}
          <div className="col-12  d-flex justify-content-center align-items-center my-3 m-0 p-2">
            {mountedOn === "taboola-ads-campaign" ||
            mountedOn === "Adcounty-Casestudy" ? (
              <>
                <h6
                  className="my-4 text-center font-weight-bold"
                  style={{ color: "#e53d54" }}
                >
                  Try free for first 6 months
                </h6>
                <BsArrowRight
                  className=" mx-2"
                  style={{ fontSize: "2rem", color: "#e53d54" }}
                />
              </>
            ) : null}
            {mountedOn === "taboola-ads-campaign" ? (
              <GetaQuoteButton type="submit" title="Submit" />
            ) : (
              <SuccessButton type="submit" title="Submit" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
