import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { cloudFrontOrigin } from "../utils/config";
const Carausel = () => {
  return (
    <div className="container my-5">
      <Carousel
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        infiniteLoop={true}
        autoPlay={true}
        style={{ backgroundColor: "white" }}
      >
        <div className="row  d-flex justify-content-center ">
          <div className="col-12 ">
            <h4 className="text-center my-5">Our Trusted Clients</h4>
          </div>
          {/* =============================================== */}
          <div className="col-12">
            <div className="row">
              <div className="col d-flex justify-content-center align-items-center">
                <img
                  src={`${cloudFrontOrigin}logo-navigant.png`}
                  alt="navigant"
                  className="trustedImages "
                />
              </div>
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-nest-away.png`}
                  alt="nest-away"
                />
              </div>
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-your-space.png`}
                  alt="your-space"
                />
              </div>
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-future-netwings.png`}
                  alt="future-netwings"
                />
              </div>
              <div className=" col d-flex  justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-hello-world.png`}
                  alt="hello-world"
                />
              </div>
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-verzen.png`}
                  alt="verzen"
                />
              </div>
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-cadopt.png`}
                  alt="cadopt"
                />
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-grexter.png`}
                  alt="grexter"
                />
              </div>{" "}
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-snap-fitness.png`}
                  alt="snap-fitness"
                />
              </div>{" "}
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-brew-kings.png`}
                  alt="brew-kings"
                />
              </div>{" "}
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-colive.png`}
                  alt="colive"
                />
              </div>{" "}
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-covee.png`}
                  alt="covee"
                />
              </div>{" "}
              <div className=" col d-flex justify-content-center align-items-center">
                <img
                  className="trustedImages  "
                  src={`${cloudFrontOrigin}logo-addo-fitness.png`}
                  alt="addo-fitness"
                />
              </div>
            </div>
          </div>
          {/* ===================================================== */}
        </div>
      </Carousel>
    </div>
  );
};

export default Carausel;
