import React from "react";
import { Helmet } from "react-helmet";

const OGtags = ({ metaTitle, metaDescription, url, ogTitle, ogDescription, ogImage }) => {
  return (
    <Helmet>
      {/* <!-- Primary Meta Tags --> */}
      <title>{metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={`https://thesecurepass.com${url}`} />
      {/* <!-- Open Graph / Facebook --> */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://thesecurepass.com${url}`} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="website" />
      <meta property="twitter:url" content={`https://thesecurepass.com${url}`} />
      <meta property="twitter:title" content={ogTitle} />
      <meta property="twitter:description" content={ogDescription} />
      <meta property="twitter:image" content={ogImage}></meta>
    </Helmet>
  );
};

export default OGtags;
