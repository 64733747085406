import React from "react";
import { cloudFrontOrigin } from "../utils/config";

const Loading = () => {
  return (
    <div className="w-100 text-center my-2">
      <img
        src={`${cloudFrontOrigin}animated-spinner.gif`}
        alt="loading"
      />
    </div>
  );
};

export default Loading;
