import React, { useEffect } from "react";
import { useState } from "react";
import { ImWhatsapp } from "react-icons/im";
const WhatsappButton = () => {
  const [waNumber, setWANumber] = useState(null);
  const [loading, setLoading] = useState(null);

  const getWANumber = () => {
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/leadController/getcontactNumber`;
    setLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setWANumber(data.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  const submitLead = (n) => {
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/leadController/submitLead/${n}`;
    fetch(url);
  };
  useEffect(() => {
    getWANumber();
  }, []);

  return (
    <div className="waButton">
      {loading || !waNumber ? (
        <div className="spinner-grow text-success" role="status">
          <span className="visually-hidden"></span>
        </div>
      ) : (
        <a
          onClick={(e) => submitLead(waNumber)}
          href={`https://api.whatsapp.com/send?phone=91${waNumber}`}
          className=" waButton d-flex justify-content-center align-items-center"
          target="_blank"
        >
          <ImWhatsapp className="m-0 p-0 waIcon" />
        </a>
      )}
    </div>
  );
};

export default WhatsappButton;
