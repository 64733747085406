import React, { useState, useEffect } from "react";
import { PageForm, validateEmail, addCampaignParams } from "../utils/config";
import { Redirect, Link } from "react-router-dom";
import { cloudFrontOrigin } from "../utils/config";
import { FaLinkedinIn, FaInstagram, FaFacebookF } from "react-icons/fa";
const Footer = ({ queryParams, setEmailUID, locationSearch }) => {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState({
    error: false,
    message: false,
  });
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    setLoading(true);
    setResult({
      error: false,
      message: false,
    });
    if (!validateEmail(email)) {
      setResult({
        error: true,
        message: "Please enter a valid email",
      });
      setLoading(false);
    } else {
      const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submit`;
      let body = {
        data: {
          email,
          PageForm: PageForm + " Subscribe form",
        },
      };
      body = addCampaignParams(queryParams, body);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setLoading(false);
          setResult({ message: "Request Submitted", success: true });
        })

        .catch((e) => {
          setLoading(false);
          setResult({
            message: "Request failed, Please try again later..",
            error: true,
          });
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setResult({
        error: false,
        message: false,
      });
    }, 4000);
  }, [result]);

  return (
    <div
      className="container-fluid bg-primary py-5"
      style={{
        minHeight: "30vh",
        background:
          "linear-gradient(37deg, rgb(189, 104, 191) 0%, rgb(221, 92, 139) 60%, rgb(241, 83, 103) 100%)",
      }}
    >
      {result.message === "Request Submitted" ? (
        <Redirect to={"/subscription-thankyou" + locationSearch} />
      ) : null}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-center">
          <div>
            <img
              src={`${cloudFrontOrigin}spTPForfooter.png`}
              className="mt-5"
              style={{ width: "200px" }}
              alt=""
            />
            <h3 className="mt-5 text-white">Singularity Automation Pvt Ltd</h3>
            <p className="mt-5 text-white">
              2nd Floor, No. 89, 27th Main Rd, Sector 2, PWD <br></br> Quarters, 1st Sector, HSR Layout,
              Bengaluru,<br></br> Karnataka 560102
            </p>
          </div>
        </div>

        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex align-items-center justify-content-md-center">
          <div className="w-100">
            <div className="col-12 m-0 p-0 my-4 d-flex align-items-center">
              <h5 style={{ color: "white", marginRight: "1rem" }}>Follow us:</h5>
              <a
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/singularity-automation-pvt.-ltd./"
                target="blank"
              >
                <FaLinkedinIn
                  style={{
                    color: "white",
                    fontSize: "2.5rem",
                    margin: "1rem 1rem",
                  }}
                />
              </a>
              <a
                rel="noopener noreferrer"
                href="https://www.instagram.com/thesecurepass/"
                target="blank"
              >
                <FaInstagram
                  style={{
                    color: "white",
                    fontSize: "2.5rem",
                    margin: "1rem 1rem",
                  }}
                />
              </a>
              <a rel="noopener noreferrer" href="https://www.facebook.com/TheSecurePass" target="blank">
                <FaFacebookF
                  style={{
                    color: "white",
                    fontSize: "2.5rem",
                    margin: "1rem 1rem",
                  }}
                />
              </a>
            </div>
            <div className="col-12 m-0 p-0 pb-3">
              <div className="bg-white subscribeDiv d-flex justify-content-center align-items-center subscribe-div rounded ">
                <div className="row w-100 h-100">
                  <div className="col-8 d-flex justify-content-center align-items-center">
                    <input
                      placeholder="Enter your email "
                      style={{ border: "none" }}
                      className="w-100 h-75"
                      type="email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailUID(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-4 d-flex justify-content-center align-items-center">
                    <button
                      className="btn btn-danger btn-lg h-75 font-weight-bold w-100"
                      onClick={handleSubmit}
                    >
                      {loading ? "Submitting..." : "Submit"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mb-5 m-0 p-0">
              <p className="mb-1">
                <Link className="font-weight-bold text-light" to="/remote-access-system">
                  Remote Access
                </Link>
              </p>
              <p className="mb-1">
                <Link className="font-weight-bold text-light" to="/access-control-system">
                  Access Control
                </Link>
              </p>
              <p className="mb-1">
                <Link className="font-weight-bold text-light" to="/visitor-management-system">
                  Visitor Management
                </Link>
              </p>
              <p className="mb-1">
                <Link className="font-weight-bold text-light" to="/attendance-management-system">
                  Attendance Management
                </Link>
              </p>
            </div>
            {/* {true ? (
              <p
                style={{
                  textAlign: "center",
                  color: `${result.error ? "#ff0000" : "#42C588"}`,
                }}
                className="my-2"
              >
                {result.message} true
              </p>
            ) : null} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

// {/* <div className="col-12">
//   <h4 className="text-center mt-4 font-weight-bold">
//     Join over 40,000 subscribers
//   </h4>
//   <p
//     className="text-center font-weight-bold text-secondary"
//     style={{ fontSize: "1.3rem" }}
//   >
//     Stay informed with the latest safety and security news, plus free guides and
//     exclusive SecurePass content
//   </p>
//   <div className="col-12 d-flex justify-content-center align-items-center">
//     {/* <div className="col-12 d-flex justify-content-center align-items-center"> */}
//     <div className="form-group">
//       <input
//         className=" subscribeInput my-3"
//         placeholder="Enter your email here"
//         type="email"
//         value={email}
//         onChange={(e) => {
//           setEmail(e.target.value);
//           setEmailUID(e.target.value);
//         }}
//       />

//       {result.message ? (
//         <p
//           style={{
//             textAlign: "center",
//             color: `${result.error ? "#ff0000" : "#42C588"}`,
//           }}
//           className="my-2"
//         >
//           {result.message}
//         </p>
//       ) : null}
//       <GetaQuoteButton
//         customclass="w-100 px-5 py-3"
//         title="Subscribe to our newsletter"
//         onClick={handleSubmit}
//       />
//       {loading ? <Loading /> : null}
//     </div>
//     {/* </div> */}
//   </div>
//   <div className="col-12 my-3  d-flex justify-content-center align-items-center">
//     <a
//       rel="noopener noreferrer"
//       href="https://www.facebook.com/TheSecurePass"
//       target="blank"
//     >
//       <img
//         style={{ width: "30px" }}
//         className="mx-3"
//         src={`${cloudFrontOrigin}facebook-3-256_zf4tsw.png`}
//         alt="facebook"
//       />
//     </a>
//     <a
//       rel="noopener noreferrer"
//       href="https://www.instagram.com/thesecurepass/"
//       target="blank"
//     >
//       <img
//         className="mx-3"
//         style={{ width: "30px" }}
//         src={`${cloudFrontOrigin}instagram-256_elja9z.png`}
//         alt="instagram"
//       />
//     </a>
// <a
//   rel="noopener noreferrer"
//   href="https://www.linkedin.com/company/singularity-automation-pvt.-ltd./"
//   target="blank"
// >
//       <img
//         style={{ width: "30px" }}
//         className="mx-3"
//         src={`${cloudFrontOrigin}linkedin-3-256_igalpx.png`}
//         alt="linkedin"
//       />
//     </a>
//   </div>
//   <div className="col-12 text-center">
//     <Link to="/privacy" className="text-center text-danger">
//       Privacy Policy
//     </Link>
//   </div>
//   <div className="col-12">
//     <p className="text-center text-danger">
//       ©2020 SecurePass All rights reserved.
//     </p>
//   </div>
// </div>; */}
