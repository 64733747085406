import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMenu, HiOutlineChevronDown } from "react-icons/hi";
import { cloudFrontOrigin } from "../utils/config";
const Nav = ({ show, pathname, setFlag, flag, setShowPopup, dimentions }) => {
  const [navClass, setNavClass] = useState("navLink");
  const location = useLocation();
  const [showDropdown, setShowDropdown] = useState("");
  useEffect(() => {
    if (dimentions.width < 991) {
      setNavClass("navLinkBlack");
    } else if (dimentions.y < 0 && dimentions.width > 991) {
      setNavClass("navLinkBlack");
    } else if (dimentions.y === 0 && dimentions.width > 991) {
      setNavClass("navLink");
    }
    if (location.pathname !== "/") {
      setNavClass("navLinkBlack");
    }
  }, [dimentions, location]);
  return (
    <nav
      className={`navbar navbar-expand-lg navbar-light fixed-top py-3 ${
        dimentions.y < 0 || location.pathname !== "/" ? "bg-light" : "nav-bar-bg"
      }`}
      style={{ transition: "all 0.4s ease", width: "100vw" }}
    >
      <div className="container-fluid" id="navContainer">
        {dimentions.y < 0 || location.pathname !== "/" ? (
          <Link onClick={(e) => setFlag(!flag)} className="navbar-brand" to="/">
            <img id="brandlogo" src={`${cloudFrontOrigin}sp_black_logo.svg`} alt="brandlogo" />
          </Link>
        ) : (
          <Link onClick={(e) => setFlag(!flag)} className="navbar-brand" to="/">
            <img
              id="brandlogo"
              src={
                dimentions.width < 991
                  ? `${cloudFrontOrigin}sp_black_logo.svg`
                  : `${cloudFrontOrigin}SPlogofordarkbg.png`
              }
              alt="brandlogo"
            />
          </Link>
        )}
        <button
          style={{ border: "none", padding: "0px 0px " }}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <HiMenu style={{ color: "red", fontSize: "2rem" }} />
          {/* <span className="navbar-toggler-icon"></span> */}
        </button>

        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul
            className=" text-center navbar-nav ml-auto d-flex align-items-center justify-content-center"
            style={show ? {} : { height: "100vh" }}
          >
            <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  location.pathname === "/"
                    ? dimentions.y === 0 && dimentions.width > 991
                      ? navClass + " active-white"
                      : navClass + " active"
                    : navClass
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/"
              >
                Home
              </Link>
            </li>
            {show ? (
              <div
                className={`dropdown ${showDropdown}`}
                onMouseOver={(e) => setShowDropdown("show")}
                onMouseLeave={(e) => setShowDropdown("")}
              >
                <p
                  onClick={(e) => setFlag(!flag)}
                  className={`m-0 cursor-pointer ${navClass} ${show ? "mr-2" : ""}`}
                  style={show ? {} : { fontSize: "2rem" }}
                >
                  Features
                </p>
                <div
                  className={`dropdown-menu ${showDropdown}`}
                  style={{ backgroundColor: "transparent", border: "none" }}
                >
                  <div className="p-2 pt-3 bg-light rounded border border-sm " style={{ width: "300px" }}>
                    <p>
                      <Link
                        onClick={(e) => setFlag(!flag)}
                        className={`${navClass} text-dark`}
                        to="/remote-access-system"
                      >
                        Remote Access
                      </Link>
                    </p>
                    <p>
                      <Link
                        onClick={(e) => setFlag(!flag)}
                        className={`${navClass} text-dark`}
                        to="/access-control-system"
                        
                      >
                        Access Control
                      </Link>
                    </p>
                    <p>
                      <Link
                        onClick={(e) => setFlag(!flag)}
                        className={`${navClass} text-dark`}
                        to="/visitor-management-system"
                      >
                        Visitor Management
                      </Link>
                    </p>
                    <p>
                      <Link
                        onClick={(e) => setFlag(!flag)}
                        className={`${navClass} text-dark`}
                        to="/attendance-management-system"
                      >
                        Attendance Management
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}

            <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  pathname.startsWith("/blogs")
                    ? dimentions.y === 0 && dimentions.width > 991
                      ? location.pathname === "/"
                        ? navClass + " active-white"
                        : navClass + " active"
                      : navClass + " active"
                    : navClass
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/blogs"
              >
                Blogs
              </Link>
            </li>

            {/* <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "mr-3 nav-item" : "my-5 nav-item"}`}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  pathname === "/pricing" ? "navLink active" : "navLink"
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/pricing"
              >
                Pricing
              </Link>
            </li> */}
            <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  pathname.startsWith("/FAQs")
                    ? dimentions.y === 0 && dimentions.width > 991
                      ? location.pathname === "/"
                        ? navClass + " active-white"
                        : navClass + " active"
                      : navClass + " active"
                    : navClass
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/faqs"
              >
                FAQs
              </Link>
            </li>
            <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "px-1 nav-item" : "my-2 nav-item"} `}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  pathname === "/contact"
                    ? dimentions.y === 0 && dimentions.width > 991
                      ? location.pathname === "/"
                        ? navClass + " active-white"
                        : navClass + " active"
                      : navClass + " active"
                    : navClass
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/contact"
              >
                Contact
              </Link>
            </li>
            <li
              data-target="#navbarTogglerDemo02"
              data-toggle="collapse"
              className={`${show ? "px-1 nav-item" : "my-2 nav-item"} `}
            >
              <Link
                onClick={(e) => setFlag(!flag)}
                className={`${
                  pathname === "/getaquote"
                    ? dimentions.y === 0 && dimentions.width > 991
                      ? location.pathname === "/"
                        ? navClass + " active-white"
                        : navClass + " active"
                      : navClass + " active"
                    : navClass
                } ${show ? "mr-2" : ""}`}
                style={show ? {} : { fontSize: "2rem" }}
                to="/getaquote"
              >
                Get a Quote
              </Link>
            </li>
            {show ? null : (
              <>
                <li
                  data-target="#navbarTogglerDemo02"
                  data-toggle="collapse"
                  className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
                >
                  <Link
                    onClick={(e) => setFlag(!flag)}
                    className={`${
                      pathname.startsWith("/remote-access-system")
                        ? dimentions.y === 0 && dimentions.width > 991
                          ? location.pathname === "/"
                            ? navClass + " active-white"
                            : navClass + " active"
                          : navClass + " active"
                        : navClass
                    } ${show ? "mr-2" : ""}`}
                    style={show ? {} : { fontSize: "2rem" }}
                    to="/remote-access-system"
                  >
                    Remote Access
                  </Link>
                </li>
                <li
                  data-target="#navbarTogglerDemo02"
                  data-toggle="collapse"
                  className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
                >
                  <Link
                    onClick={(e) => setFlag(!flag)}
                    className={`${
                      pathname.startsWith("/access-control-system")
                        ? dimentions.y === 0 && dimentions.width > 991
                          ? location.pathname === "/"
                            ? navClass + " active-white"
                            : navClass + " active"
                          : navClass + " active"
                        : navClass
                    } ${show ? "mr-2" : ""}`}
                    style={show ? {} : { fontSize: "2rem" }}
                    to="/access-control-system"
                  >
                    Access Control
                  </Link>
                </li>
                <li
                  data-target="#navbarTogglerDemo02"
                  data-toggle="collapse"
                  className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
                >
                  <Link
                    onClick={(e) => setFlag(!flag)}
                    className={`${
                      pathname.startsWith("/visitor-management-system")
                        ? dimentions.y === 0 && dimentions.width > 991
                          ? location.pathname === "/"
                            ? navClass + " active-white"
                            : navClass + " active"
                          : navClass + " active"
                        : navClass
                    } ${show ? "mr-2" : ""}`}
                    style={show ? {} : { fontSize: "2rem" }}
                    to="/visitor-management-system"
                  >
                    Visitor Management
                  </Link>
                </li>
                <li
                  data-target="#navbarTogglerDemo02"
                  data-toggle="collapse"
                  className={`${show ? "px-1 nav-item" : "my-2 nav-item"}`}
                >
                  <Link
                    onClick={(e) => setFlag(!flag)}
                    className={`${
                      pathname.startsWith("/attendance-management-system")
                        ? dimentions.y === 0 && dimentions.width > 991
                          ? location.pathname === "/"
                            ? navClass + " active-white"
                            : navClass + " active"
                          : navClass + " active"
                        : navClass
                    } ${show ? "mr-2" : ""}`}
                    style={show ? {} : { fontSize: "2rem" }}
                    to="/attendance-management-system"
                  >
                    Attendance Management
                  </Link>
                </li>
              </>
            )}
            {/* <li
              onClick={(e) => setShowPopup(true)}
              className="nav-item mr-3"
              style={show ? {} : { display: "none" }}
            >
              <CaseStudyButton id={"caseStudyButton"} />
            </li>
            <li className="nav-item" style={show ? {} : { display: "none" }}>
              <Link to="/getaquote" onClick={(e) => setFlag(!flag)}>
                <GetaQuoteButton title={"Get a Quote"} />
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
