import React, { useEffect, useState, lazy, Suspense } from "react";
import "./App.css";
import Nav from "./components/Nav";
// import Home from "./pages/Home";
import HomeV2 from "./pages/HomeV2";
import useDimensions from "react-use-dimensions";
import Footer from "./components/Footer";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useMediaQuery } from "./hooks/UseMediaQuery";
import ScrollToTop from "./components/ScrollToTop";
import { blogData } from "./blogs";
import { cloudFrontOrigin } from "./utils/config";
import WhatsappButton from "./components/WhatsappButton";
import KnowMoreForm from "./components/KnowMoreForm";
import VMS from "./pages/VMS";
import NewsLetterPage from "./pages/NewsLetterPage";
import { addCampaignParams } from "./utils/config";
import ConnectPlusCampaign from "./pages/ConnectPlusCampaign";
import LandingPageVisitorManagementSystem from "./pages/LandingPageVisitorManagementSystem";
import LandingPageEmployeeAccessControl from "./pages/LandingPageEmployeeAccessControl";
import LandingPageEmployeesTimeAndAttendanceTracker from "./pages/LandingPageEmployeesTimeAndAttendanceTracker";
import LandingPageRemoteAccessManagement from "./pages/LandingPageRemoteAccessManagement";
import ObsoleteBlogs from "./components/blogs/ObsoleteBlogs";
const AdcountyDownloadCasestudy = lazy(() => import("./pages/AdcountyDownloadCasestudy"));
const AdCountyThankYou = lazy(() => import("./pages/AdCountyThankYou"));
const Contact = lazy(() => import("./pages/Contact"));
const Popup = lazy(() => import("./components/Popup"));
const DownloadCaseStudy = lazy(() => import("./pages/DownloadCaseStudy"));
const TaboolaAdsCampaign = lazy(() => import("./pages/TaboolaAdsCampaign"));
const GetaQuote = lazy(() => import("./pages/GetaQuote"));
const FourOFour = lazy(() => import("./pages/FourOFour"));
const Pricing = lazy(() => import("./pages/Pricing"));
const Privacy = lazy(() => import("./pages/Privacy"));
const FAQs = lazy(() => import("./pages/FAQs"));
const Dealers = lazy(() => import("./pages/Dealers"));
const Blog = lazy(() => import("./pages/Blog"));

export const Empty = () => {
  return (
    <div
      style={{ height: "100vh", width: "100vw", backgroundColor: "whitesmoke" }}
      className="d-flex justify-content-center align-items-center"
    >
      <img src={`${cloudFrontOrigin}animated-spinner.gif`} alt="loading" className="m-5" />
    </div>
  );
};

function App() {
  const [queryParams, setQueryParams] = useState(null);
  const [pathname, setPathName] = useState(window.location.pathname);
  const [locationSearch, setLocationSearch] = useState("");
  const [flag, setFlag] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const show = useMediaQuery(`(min-width:992px)`);
  const [emailUID, setEmailUID] = useState(null);
  const [main, dimentions] = useDimensions();

  useEffect(() => {
    if (!window.location.pathname.startsWith("/dealers")) {
      document.addEventListener("mouseout", (e) => {
        if (!e.toElement && !e.realtedTarget && !sessionStorage.getItem("mouseOutTriggered")) {
          sessionStorage.setItem("mouseOutTriggered", "true");
          setShowPopup(true);
        }
      });

      return () => {
        document.removeEventListener("mouseout");
      };
    }
  }, []);

  useEffect(() => {
    window.dataLayer.push({
      event: "pageview",
    });
  }, [window.location.pathname]);

  useEffect(() => {
    setPathName(window.location.pathname);
  }, [flag]);
  useEffect(() => {
    let params = new URLSearchParams(window.location.search);
    setQueryParams(params);
    setLocationSearch(window.location.search);
  }, []);
  return (
    <div className="App" ref={main}>
      <BrowserRouter>
        {/* <GoogleAnalytics /> */}
        <Suspense fallback={<Empty />}>
          <ScrollToTop />
          {showPopup &&
          !window.location.pathname.startsWith("/get-in-touch") &&
          !window.location.pathname.startsWith("/connectPlus") ? (
            <Popup
              locationSearch={locationSearch}
              setShowPopup={setShowPopup}
              setEmailUID={setEmailUID}
              queryParams={queryParams}
            />
          ) : null}
          <div className="App container-fluid m-0 p-0" style={{ minHeight: "100vh" }}>
            <Nav
              dimentions={dimentions}
              show={show}
              pathname={pathname}
              setFlag={setFlag}
              flag={flag}
              setShowPopup={setShowPopup}
            />
            <div>
              <div style={{ minHeight: "62vh" }}>
                <WhatsappButton />
                <Switch>
                  <Route exact path="/">
                    <HomeV2
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  {/* <Route exact path="/pricing">
                    <Pricing setFlag={setFlag} flag={flag} />
                  </Route> */}
                  <Route exact path="/contact">
                    <Contact
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  {/* adcounty campaign */}
                  <Route exact path="/download-casestudy">
                    <AdcountyDownloadCasestudy
                      setEmailUID={setEmailUID}
                      locationSearch={locationSearch}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  {/* //taboola campaign */}
                  <Route exact path="/get-in-touch">
                    <TaboolaAdsCampaign
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>

                  <Route exact path="/thankyou">
                    <AdCountyThankYou
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      queryParams={queryParams}
                    />
                  </Route>

                  <Route exact path="/know-more">
                    <KnowMoreForm
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>

                  <Route exact path="/download-casestudy-thankyou">
                    <DownloadCaseStudy
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      caseStudy={true}
                      queryParams={queryParams}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  <Route exact path="/privacy-policy">
                    <Privacy
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      queryParams={queryParams}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  <Route exact path="/subscription-thankyou">
                    <DownloadCaseStudy
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      queryParams={queryParams}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  <Route exact path="/getaquote-thankyou">
                    <DownloadCaseStudy
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      queryParams={queryParams}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  <Route exact path="/contact-thankyou">
                    <DownloadCaseStudy
                      locationSearch={locationSearch}
                      emailUID={emailUID}
                      queryParams={queryParams}
                      setShowPopup={setShowPopup}
                    />
                  </Route>
                  <Route exact path="/getaquote">
                    <GetaQuote
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/six-months-offer">
                    <NewsLetterPage
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/dealer-partner">
                    <Dealers
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>

                  <Route exact path="/access-control-system">
                    <LandingPageEmployeeAccessControl
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/attendance-management-system">
                    <LandingPageEmployeesTimeAndAttendanceTracker
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/remote-access-system">
                    <LandingPageRemoteAccessManagement
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/visitor-management-system">
                    <LandingPageVisitorManagementSystem
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route path="/blogs/:pillarTopic?/:subTopic?">
                    <Blog
                      locationSearch={locationSearch}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>

                  {blogData.map((v, i) => (
                    <Route exact path={v.url} key={v.url}>
                      <v.template index={i} />
                    </Route>
                  ))}

                  <Route exact path="/pricacy-policy">
                    <Privacy setFlag={setFlag} flag={flag} queryParams={queryParams} />
                  </Route>
                  <Route exact path="/connectPlus">
                    <ConnectPlusCampaign
                      locationSearch={locationSearch}
                      setEmailUID={setEmailUID}
                      setFlag={setFlag}
                      flag={flag}
                      queryParams={queryParams}
                    />
                  </Route>
                  <Route exact path="/faqs">
                    <FAQs />
                  </Route>
                  <ObsoleteBlogs />
                  <Route>
                    <FourOFour />
                  </Route>
                </Switch>
              </div>
              {window.location.pathname === "/download-casestudy" ||
              window.location.pathname === "/get-in-touch" ? null : (
                <Footer
                  locationSearch={locationSearch}
                  setEmailUID={setEmailUID}
                  setFlag={setFlag}
                  flag={flag}
                  queryParams={queryParams}
                />
              )}
            </div>
          </div>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
