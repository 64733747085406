import React, { lazy, Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Empty } from "../App";
import CountUp from "react-countup";
import { cloudFrontOrigin } from "../utils/config";
import { AiOutlineCheck } from "react-icons/ai";
import useDimensions from "react-use-dimensions";
import { Link } from "react-router-dom";
import Carausel from "../components/Carausel";
import OGtags from "../components/OGtags";
import { metaTags } from "../meta";
const VisitorManagementCarausel = lazy(() => import("../components/VisitorManagementCarausel"));

const HomeV2 = ({ setFlag, flag, setShowPopup, setEmailUID }) => {
  const [countRef, dimentions] = useDimensions();
  const [count, setCount] = useState(null);

  let pageHeight = window.innerHeight;

  useEffect(() => {
    const url = `https://api.snglty.com/v2/marketing/getcount`;
    fetch(url)
      .then((res) => res.json())
      .then((res) => setCount({ ...res }))
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <OGtags
        metaTitle={metaTags.home.metaTitle}
        metaDescription={metaTags.home.metaDescription}
        url={metaTags.home.url}
        ogTitle={metaTags.home.ogTitle}
        ogDescription={metaTags.home.ogDescription}
        ogImage={metaTags.home.ogImage}
      />
      <div className="container-fluid m-0 p-0">
        <div className="hero-image">
          <video
            src={`${cloudFrontOrigin}main_video1_kpaeqn_zy8ega.mp4`}
            style={{
              objectFit: "cover",
              position: "absolute",
              height: "100%",
              width: "100%",
              top: "0px",
              left: "0px",
            }}
            alt="main"
            autoPlay
            muted
            loop
          />
          <div className="hero-text">
            <h1 id="heroHeading">Contactless Access Control Solutions For Your Facility</h1>
            <div className="row">
              <div className="col-12 py-3">
                <p id="heroPrice">Price Starts At Rs. 849/- Per Month</p>
              </div>
              <div className="col-12 d-flex justify-content-center align-items-center">
                <Link to="/contact" onClick={(e) => setFlag(!flag)}>
                  <button className="btn btn-danger btn-lg font-weight-bold px-5 py-2">
                    Try For Free
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12" className="appsRow" style={{ color: "#5D5D5D" }}>
          <div className="row m-0 p-0" ref={countRef}>
            <div className="col-12 text-center my-5">
              <h4>Our Customers Trust Us</h4>
            </div>
            <div className="col-sm-6 col-xs-6 col-md-6 col-lg-3 m-0 p-0 d-flex justify-content-center align-items-center mb-5">
              <div className="text-center">
                <h4 className="counterTitle">No Of Doors</h4>
                <h1 className="counterFont">
                  <CountUp
                    end={count && count.success && dimentions.y <= 600 ? count.doorCount + 5983 : 0}
                    separator=","
                    duration={1}
                  />
                </h1>
              </div>
            </div>
            <div className="col-sm-6 col-xs-6 col-md-6 col-lg-3 m-0 p-0 d-flex justify-content-center align-items-center  mb-5">
              <div className="text-center">
                <h4 className="counterTitle">No Of Unlocks</h4>
                <h1 className="counterFont">
                  <CountUp
                    end={count && count.success && dimentions.y <= 600 ? count.unlockCount + 2714411 : 0}
                    separator=","
                    duration={1}
                  />
                </h1>
              </div>
            </div>
            <div className="col-sm-6 col-xs-6 col-md-6 col-lg-3 m-0 p-0 d-flex justify-content-center align-items-center mb-5">
              <div className="text-center">
                <h4 className="counterTitle">No Of Users</h4>
                <h1 className="counterFont">
                  <CountUp
                    end={count && count.success && dimentions.y <= 600 ? count.userCount + 100653 : 0}
                    separator=","
                    duration={1}
                  />
                </h1>
              </div>
            </div>
            <div className="col-sm-6 col-xs-6 col-md-6 col-lg-3 m-0 p-0 d-flex justify-content-center align-items-center mb-5">
              <div className="text-center">
                <h4 className="counterTitle">No Of Clients</h4>
                <h1 className="counterFont">
                  <CountUp
                    end={count && count.success && dimentions.y <= 600 ? count.clientCount + 10 : 0}
                    separator=","
                    duration={1}
                  />
                </h1>
              </div>
            </div>
          </div>
        </div>
        <Carausel />
        <div
          className="col-12 text-white  py-5"
          style={{
            background: "linear-gradient(174deg, rgb(0, 181, 198) 0%, rgb(1, 173, 168) 100%)",
          }}
        >
          <div className="row">
            <div className="col-12 text-center">
              <h4>Listen From Our Customers</h4>
            </div>
            <div className="container pt-5">
              <div className="row">
                <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 text-center rounded">
                  <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/ru-5dJ4uCZc"
                    frameBorder="0"
                    allow="accelerometer; autoplay;fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>

                  <h5 className="font-weight-normal my-3">Mystic Apartment, Pune</h5>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 text-center rounded">
                  <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/SK_3R4o6pTw"
                    frameBorder="0"
                    allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <h5 className="font-weight-normal my-3">InShorts Office, Delhi</h5>
                </div>
                <div className="col-sm-12 col-xs-12 col-md-4 col-lg-4 text-center rounded">
                  <iframe
                    width="80%"
                    src="https://www.youtube.com/embed/BtDpFSsXQFE"
                    frameBorder="0"
                    allow="accelerometer; autoplay;fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                  <h5 className="font-weight-normal my-3">Safe Storage Office, Bengaluru</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid m-0 p-0">
          <div className="row m-0 p-0 " style={{ backgroundColor: "#eeeeee" }}>
            <div className=" col-xs-12 d-flex align-items-center justify-content-center col-sm-12 col-md-7 col-lg-7 m-0 p-0 ">
              <div
                className="gradintcontentbox d-flex align-items-center justify-content-center py-5"
                style={{ width: "100%" }}
              >
                <div className="col-md-10 col-lg-10 col-xs-12 col-sm-12">
                  <div className="col-12">
                    <h1 className="poster-heading">Future-Proof Mobile Access Control</h1>
                    <p className="poster-description">One-click entry using phone. safe and secure.</p>
                  </div>
                  <div className="col-xs-0 col-sm-0 col-md-12 col-lg-12 ">
                    <video
                      className="descriptiveVideo shadow shadow-sm rounded"
                      src={`${cloudFrontOrigin}accesscontrolgif_putgr5_optimized_le8ehd.mov`}
                      autoPlay={true}
                      loop={true}
                      muted={true}
                    ></video>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5 m-0 p-0 text-right">
              <img
                id="blockImage"
                // className="imgCover"
                src={`${cloudFrontOrigin}3 (1).png`}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0 reverseOnMobile" style={{ backgroundColor: "#E8F2F2" }}>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0">
            <img
              className="w-100 h-100 imgCover"
              src={`${cloudFrontOrigin}SecurePass Brochuree-5-1-1 (1).png`}
              alt=""
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0 py-5 h-100 d-flex align-items-center justify-content-center">
            <div className="gradintcontentbox py-5">
              <div className="col-12">
                <h1 className="poster-heading">Fever Detection</h1>
                <p className="poster-description">
                  Body temperature scanning to detect fever. Restrict entry of the person and send
                  alerts.
                </p>
              </div>
              <div className="col-xs-0 col-sm-0 col-md-12 col-lg-12 ">
                <video
                  className="descriptiveVideo shadow shadow-sm rounded"
                  src={`${cloudFrontOrigin}accesswithtempgif_ijmzxb_optimized_f1r2pn.mov`}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid m-0 p-0  py-5"
        // style={{ backgroundColor: "black" }}
      >
        <div className="row reverseOnMobile m-0 p-0">
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center py-3">
            <video
              style={{ height: "600px" }}
              src={`${cloudFrontOrigin}appView_new.mp4`}
              autoPlay={true}
              loop={true}
              muted={true}
            ></video>
          </div>
          <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center">
            {/* <div className="gradintcontentbox py-3 px-5" style={{backgroundColor : "white", borderRadius : "20px"}}> */}
            <div className="gradintcontentbox">
              <h1 className="poster-heading">Remote Access Management</h1>

              <div className="col-12">
                <div className="row">
                  <div className="col-1">
                    <AiOutlineCheck />
                  </div>
                  <div className="col-10">
                    <p className="poster-description" style={{ letterSpacing: "1px" }}>
                      Grant & revoke access to people from anywhere using our centralized dashboard.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-1">
                    <AiOutlineCheck />
                  </div>
                  <div className="col-10">
                    <p className="poster-description" style={{ letterSpacing: "1px" }}>
                      No need to install expensive equipment like WiFi and LAN at the property.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0 py-5">
        <div className="row m-0 p-0">
          <div className="col-12 text-center py-3" style={{ backgroundColor: "#EFEFEF" }}>
            <h1
              className="py-3"
              style={{
                fontFamily: `"Manrope", sans-serif`,
                fontWeight: "800",
                color: "#6C6C6C",
              }}
            >
              Don’t Have A Mobile? No problem.
            </h1>
          </div>
          <div className="col-12 m-0 p-0">
            <div
              className="row m-0 p-0 reverseOnMobile"
              style={{
                background:
                  "linear-gradient(80deg, rgb(181, 107, 204) 0%, rgb(213, 96, 153) 40%, rgb(243, 82, 99) 80%, rgb(255, 78, 80) 100%)",

                color: "white",
              }}
            >
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0 ">
                <img className="imgCover" src={`${cloudFrontOrigin}Mask Group 3 (3).png`} alt="" />
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center gradintcontentbox">
                <div className="row d-flex justify-content-center">
                  <div className="col-12">
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <h1 className="text-white poster-heading">OTP Based Checkin</h1>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              Unique OTP for each user
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              No need for a phone to get entry
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              Works in locations with no access to internet
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="row m-0 p-0"
              style={{
                background:
                  "linear-gradient(80deg, rgb(181, 107, 204) 0%, rgb(213, 96, 153) 40%, rgb(243, 82, 99) 80%, rgb(255, 78, 80) 100%)",

                color: "white",
              }}
            >
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 d-flex align-items-center justify-content-center gradintcontentbox">
                <div className="row d-flex justify-content-center">
                  <div className="col-12">
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <h1 className="text-white poster-heading">NFC/RFID Checkin</h1>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              Unique NFC/RFID tag for each user
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              Works with ID cards as well as mobile phones
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div style={{ maxWidth: "400px" }}>
                        <div className="row">
                          <div className="col-1">
                            <AiOutlineCheck />
                          </div>
                          <div className="col-10">
                            <p
                              className="poster-description text-white"
                              style={{ letterSpacing: "1px" }}
                            >
                              Works in locations with no access to internet
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0">
                <img className="imgCover" src={`${cloudFrontOrigin}20_copy.png`} alt="" />
              </div>
            </div>
            <div className="row m-0 p-0 reverseOnMobile" style={{ backgroundColor: "#EBE2DF" }}>
              <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7 m-0 p-0">
                <img
                  className="imgCoverScan"
                  src={`${cloudFrontOrigin}SecurePass Brochuree-10.png`}
                  alt=""
                  id="userQRscanImage_2"
                />
              </div>
              <div className="gradintcontentbox col-xs-12 col-sm-12 col-md-5 col-lg-5 d-flex align-items-center justify-content-center px-5">
                <div style={{ maxWidth: "600px" }}>
                  <h1 className="poster-heading" style={{ color: "#7F7F7F !important" }}>
                    Built-In Visitor Management
                  </h1>
                  <p className="poster-description" style={{ color: "#7F7F7F !important" }}>
                    Monitor visitor entry & exit using our built-in visitor management system.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<Empty />}>
        <VisitorManagementCarausel />
      </Suspense>

      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0" style={{ backgroundColor: "#ececec" }}>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0 py-5 d-flex justify-content-end align-items-center">
            <div className="gradintcontentbox attendanceBox">
              <h1 className="poster-heading">Time And Attendance</h1>
              <p className="poster-description">
                A comprehensive time and attendance system to manage your workflow which is accessible
                from a centralized cloud based dashboard.
              </p>

              <div className="col-12">
                <div className="row">
                  <div className="col-6 col-md-12 col-lg-12 ">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xs-12 d-flex justify-content-center align-items-center">
                        <img
                          className="attendanceIcons"
                          src={`${cloudFrontOrigin}noun_verified fingerprint_2410213.svg`}
                          alt=""
                        />
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-9 col-xs-12 d-flex align-items-center attendancePointers">
                        <p className="attandance-pointers">Daily Attendance</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-12 ">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xs-12 d-flex justify-content-center align-items-center">
                        <img
                          className="attendanceIcons"
                          src={`${cloudFrontOrigin}Group-398.svg`}
                          alt=""
                        />
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-9 col-xs-12 d-flex align-items-center attendancePointers">
                        <p className="attandance-pointers">In and out time</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-12 ">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xs-12 d-flex justify-content-center align-items-center">
                        <img
                          className="attendanceIcons"
                          src={`${cloudFrontOrigin}Group-397.svg`}
                          alt=""
                        />
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-9 col-xs-12 d-flex align-items-center attendancePointers">
                        <p className="attandance-pointers">Leave and Overtime calculation</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-12 col-lg-12 ">
                    <div className="row">
                      <div className="col-sm-12 col-md-3 col-lg-3 col-xs-12 d-flex justify-content-center align-items-center">
                        <img
                          className="attendanceIcons"
                          src={`${cloudFrontOrigin}noun_overtime_2735394.svg`}
                          alt=""
                        />
                      </div>
                      <div className="col-sm-12 col-md-9 col-lg-9 col-xs-12 d-flex align-items-center attendancePointers">
                        <p className="attandance-pointers">Late and halfday calculation</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 m-0 p-0">
            <img className="imgCover" src={`${cloudFrontOrigin}SecurePass (1).png`} alt="" />
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0  py-5">
        <div className="row m-0 p-0">
          <div className="col-12 p-0 m-0">
            <div className="col-12 text-center py-5">
              <h1>Built In Cloud Apps</h1>
            </div>
            <div className="col-12 text-center">
              <p style={{ fontSize: "1.2rem" }}>
                Our wide range of apps are customized to fit all your needs. apps are built into your
                dashboard, and work on desktop or phone.
              </p>
            </div>

            <div className="col-12 appsRow">
              <div className="row m-0 p-0 ">
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}03Mobile-and-cloud-access-icon.png`} alt="" />
                    <h5 className="my-3">Access Control</h5>
                  </div>
                </div>
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}05Visitor-management-icon.png`} alt="" />
                    <h5 className="my-3">Visitor</h5>
                  </div>
                </div>
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}01conf-icon.png`} alt="" />
                    <h5 className="my-3">Booking</h5>
                  </div>
                </div>
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}02Group-37-icon.png`} alt="" />
                    <h5 className="my-3">Crowd Control</h5>
                  </div>
                </div>
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}04Time-and-attendance-icon.png`} alt="" />
                    <h5 className="my-3">Attendance</h5>
                  </div>
                </div>
                <div className="col-6 col-md-2 col-lg-2 d-flex justify-content-center align-items-center p-0 mx-0 my-5">
                  <div className="text-center">
                    <img src={`${cloudFrontOrigin}00Group-36-icon.png`} alt="" />
                    <h5 className="my-3">Rent</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div className="col-12 m-0 p-0 text-center">
            <img
              className="w-75"
              src={`${cloudFrontOrigin}mac-phone-access-control_to4uev.png`}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 p-0">
        <div className="row m-0 p-0">
          <div
            className="col-12"
            style={{
              background: "linear-gradient(147deg, rgb(0, 181, 198) 0%, rgb(0, 175, 177) 100%)",
            }}
          >
            <div className="row pb-5">
              <div className="col-12 text-center pt-5 d-flex align-items-center justify-content-center">
                <h1 className="text-white">Additional Features</h1>
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center">
                <div>
                  <h1 className="poster-heading text-white">Tailgating Detection</h1>
                  <p className="poster-description text-white">
                    Detect unauthorized entries in the building using our industry leading tailgating
                    detection feature.
                    <br />
                    <br />
                    SecurePass uses a combination of door sensor and people counter to send smart alerts.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex justify-content-center align-items-center">
                <img className="adimages rounded" src={`${cloudFrontOrigin}Image 10.png`} alt="" />
              </div>
            </div>
          </div>

          <div
            className="col-md-6 col-lg-6 col-xs-12 py-5 col-sm-12 d-flex justify-content-center align-items-center bottomrow"
            style={{ backgroundColor: "#F4F4F4" }}
          >
            <img
              className="adimages rounded"
              src={`${cloudFrontOrigin}social-distancing_nv5w2b.svg`}
              alt=""
              style={{ height: "100%" }}
            />
          </div>
          <div
            className="d-flex justify-content-center py-5 align-items-center col-md-6 col-lg-6 col-xs-12 col-sm-12    bottomrow"
            style={{ backgroundColor: "#F4F4F4" }}
          >
            <div>
              <h1 className="poster-heading">Crowd Management</h1>
              <p className="poster-description">
                Regulate and monitor the number of people at each floor, room, or any location using our
                crowd management feature.
              </p>
            </div>
          </div>
          <div
            className="d-flex justify-content-center align-items-center py-5 col-md-6 col-lg-6 col-xs-12 col-sm-12    bottomrow"
            style={{ backgroundColor: "#E8E8E8" }}
          >
            <div>
              <h1 className="poster-heading">People Counter</h1>
              <p className="poster-description">
                Enforce social distancing at your facility using our people counter.
                <br />
                <br />
                Receive smart alerts when the number of people inside the location exceeds a threshold.
              </p>
            </div>
          </div>
          <div
            className="col-md-6 col-lg-6 col-xs-12 col-sm-12 d-flex py-5 justify-content-center align-items-center   bottomrow"
            style={{ backgroundColor: "#E8E8E8" }}
          >
            <img className="adimages rounded" src={`${cloudFrontOrigin}8-1 (1).jpg`} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeV2;
