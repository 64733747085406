import React, { useState } from "react";
import Loading from "../components/Loading";
import { addCampaignParams, cloudFrontOrigin, PageForm } from "../utils/config";
import { Redirect } from "react-router-dom";
import OGtags from "../components/OGtags";
import { metaTags } from "../meta";
import { Carousel } from "react-responsive-carousel";
import TestimonialCarausel from "../components/TestimonialCarausel";
import ClientsCarauselTileTemplate from "../components/ClientsCarauselTileTemplate";
const LandingPageEmployeeAccessControl = ({
  setFlag,
  flag,
  queryParams,
  setEmailUID,
  locationSearch,
}) => {
  const [formData, setFormData] = useState({});
  const [formData2, setFormData2] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [result2, setResult2] = useState(null);
  const [loading2, setLoading2] = useState(false);
  const [mobileError2, setMobileError2] = useState(false);

  const handleChange2 = (e) => {
    e.stopPropagation();
    if (mobileError2) {
      setMobileError2(false);
    }
    if (e.target.name === "phone" && isNaN(e.target.value)) {
      setMobileError2("Please enter a valid Phone No");
    } else {
      setFormData2({ ...formData2, [e.target.name]: e.target.value });
      if (e.target.name === "email") {
        setEmailUID(e.target.value);
      }
    }
  };

  const handleChange = (e) => {
    e.stopPropagation();
    if (mobileError) {
      setMobileError(false);
    }
    if (e.target.name === "phone" && isNaN(e.target.value)) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      if (e.target.name === "email") {
        setEmailUID(e.target.value);
      }
    }
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    if (`${formData2.phone}`.length !== 10) {
      setMobileError2("Please enter a valid Phone No");
    } else {
      setLoading2(true);
      const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submitLandingPageLeads`;
      let body = {
        data: {
          ...formData2,
          PageForm: PageForm,
        },
      };
      body = addCampaignParams(queryParams, body);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setLoading2(false);
          setResult2({ message: "Request Submitted", success: true });
          // setShowPopup(false);
        })

        .catch((e) => {
          setLoading2(false);
          setResult2({
            message: "Request failed, Please try again later..",
            success: false,
          });
        });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (`${formData.phone}`.length !== 10) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setLoading(true);
      const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submitLandingPageLeads`;
      let body = {
        data: {
          ...formData,
          PageForm: PageForm,
        },
      };
      body = addCampaignParams(queryParams, body);
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setLoading(false);
          setResult({ message: "Request Submitted", success: true });
          // setShowPopup(false);
        })

        .catch((e) => {
          setLoading(false);
          setResult({
            message: "Request failed, Please try again later..",
            success: false,
          });
        });
    }
  };

  return (
    <div style={{ backgroundColor: "rgba(237, 240, 247, 1)" }} className="container-fluid pt-5 p-0">
      {(result && result.success) || (result2 && result2.success) ? (
        <Redirect to={"/download-casestudy-thankyou" + locationSearch} />
      ) : null}
      {/* <div className="row m-0 p-0 py-5"> */}
      <OGtags
        metaTitle={metaTags.dealerPartner.metaTitle}
        metaDescription={metaTags.dealerPartner.metaDescription}
        url={metaTags.dealerPartner.url}
        ogTitle={metaTags.dealerPartner.ogTitle}
        ogDescription={metaTags.dealerPartner.ogDescription}
        ogImage={metaTags.dealerPartner.ogImage}
      />
      <div
        className="row lp-top-row"
        style={{
          backgroundImage: `url(${cloudFrontOrigin}bg-top.svg)`,
        }}
      >
        <div className="col-12 pt-5 d-flex align-items-center justify-content-center">
          <h1
            className="text-center px-3 font-weight-bold"
            style={{ color: "rgba(255, 255, 255, 1)", fontSize: "2.5rem" }}
          >
            Employee Access Control System
          </h1>
        </div>
        {/* <div className="row "> */}
        {/* <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12"></div> */}
        <div className="col-md-7 col-lg-7 col-xs-12 col-sm-12 mt-2 d-flex justify-content-end align-items-start pb-5">
          <div className="row mt-5 px-3" style={{ maxWidth: "800px" }}>
            <div className="col-12 d-flex justify-content-center align-items-center my-2">
              <h3
                style={{
                  color: "rgba(255, 255, 255, 1)",
                  fontWeight: "700",
                  lineHeight: "auto",
                  maxWidth: "600px",
                  fontSize: "2rem",
                }}
              >
                Manage employee access with 3 different modes of access management that are fast &
                flexible
              </h3>
            </div>
            <div className="col-12 d-flex justify-content-center align-items-center my-2">
              <img
                src={`${cloudFrontOrigin}office-landingpage-thumb.svg`}
                alt="handshake"
                style={{ maxWidth: "100%" }}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
        <div className="col-md-5 text-center col-lg-5 col-xs-12 col-sm-12 px-5 py-2">
          <div
            className="col-12 text-center p-5 bg-white"
            style={{
              maxWidth: "700px",
              borderRadius: "15px",
              boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h3 className="my-4 text-left font-weight-bold" style={{ color: "rgba(229, 61, 84, 1)" }}>
              SecurePass- Starting at Rs. 849/- Per Month Only
            </h3>
            <form onSubmit={handleSubmit} className="">
              <input
                placeholder="First Name"
                type="text"
                name="firstName"
                required
                onChange={handleChange}
                className="dealer-input"
              />
              <input
                placeholder="Last Name"
                type="text"
                name="lastName"
                required
                onChange={handleChange}
                className="dealer-input"
              />
              <input
                placeholder="Email"
                className="dealer-input"
                type="email"
                name="email"
                required
                onChange={handleChange}
              />
              <input
                placeholder="Phone"
                className="dealer-input"
                type="text"
                name="phone"
                required
                onChange={handleChange}
              />
              {mobileError ? (
                <p className="w-100 text-center">
                  <small className="text-danger font-weight-bold">{mobileError}</small>
                </p>
              ) : null}
              <input
                placeholder="Organization"
                type="text"
                name="companyName"
                className="dealer-input"
                required
                onChange={handleChange}
              />
              <div className="col-12">{loading ? <Loading /> : null}</div>
              <div className="col-12">
                {result ? (
                  <p
                    style={{
                      textAlign: "center",
                      color: `${result.success ? "#42C588" : "red"}`,
                    }}
                    className="my-2"
                  >
                    {result.message}
                  </p>
                ) : null}
              </div>
              <div className="col-12 text-center mt-3">
                <input type="submit" className="button-red" />
              </div>
            </form>
          </div>
          {/* </div> */}
          {/* <div className="col-md-1 col-lg-1 col-xs-12 col-sm-12"></div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12 pt-5 text-center">
          <h3 className="m-0" style={{ color: "rgba(245, 25, 51, 1)", fontWeight: "700" }}>
            USAGE
          </h3>
        </div>
      </div>
      <div className="col-12 mt-5 text-center">
        <h4 className="mt-5" style={{ color: "rgba(245, 25, 51, 1)", fontWeight: "700" }}>
          Who Can Use SecurePass as Access Control System
        </h4>
      </div>
      <div className="col-12 mt-5 text-center">
        <h5 className="mt-5" style={{ color: "rgba(115, 115, 115, 1)", fontWeight: "800" }}>
          Our Clients
        </h5>
      </div>
      <div className="row p-5">
        <div className="col-12 text-center">
          <Carousel
            showThumbs={false}
            showArrows={true}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            style={{ backgroundColor: "white" }}
          >
            <ClientsCarauselTileTemplate
              heading="Corporate"
              icon="corporate-client.svg"
              logo="axon-client.jpg"
              useCase="SecurePass allows corporates to effectively manage access for their large number of employees through the usage of multi-mode access management."
              testimony="SecurePass installed at Axon Technologies office uses offline key code & remote RF card to track access for different categories of employees."
            />
            <ClientsCarauselTileTemplate
              heading="Co-living"
              icon="coliving-client.svg"
              logo="colive-logo.svg"
              useCase="SecurePass as an employee access management solution can prove to very useful for managing access for different categories of employees & also track time & attendance daily. "
              testimony="SecurePass installed at Colive enabled them to manage employee access at 150+ properties in India with 50-60 regularly changing residents."
            />
            <ClientsCarauselTileTemplate
              heading="Gym"
              icon="gym-client.svg"
              logo="snap-fitness-client.jpg"
              useCase="SecurePass allows gyms to restrict the entry of all employees to all areas of the gym depending on the role. "
              testimony="SecurePass installed at Snap Fitness Gym with 300 members enabled them to restrict the entry of support staff to confidential areas of the gym like the managers’ cabin.  "
            />
            <ClientsCarauselTileTemplate
              heading="SME"
              icon="sme-client.svg"
              logo="safe-storage-client.jpg"
              useCase="SecurePass at SMEs can help them to manage regular, temporary & support staff access inside the office premise using different modes of access.   "
              testimony="SecurePass installed at Safe Storage, leading warehouse, allowed them to restrict the entry of temporary staff to certain specific areas at the premise. "
            />
          </Carousel>
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundImage: `url(${cloudFrontOrigin}bg-mid-tall.svg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="col-12 pt-5 mt-5 text-center">
          <h3 className="m-0 mt-5 pt-5" style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "700" }}>
            FEATURES
          </h3>
        </div>
        {/* first row */}
        <div className="col-12 my-5">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex align-items-center">
              <img
                src={`${cloudFrontOrigin}ac-cloud-1.svg`}
                className="cloud-images-thumbs-odd"
                alt=""
                srcset=""
              />
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex text-md-odd align-items-center">
              <div>
                <h5 style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "800", fontSize: "1.7rem" }}>
                  3 Different Modes of Access:
                </h5>
                <p style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "600", fontSize: "1.5rem" }}>
                  Offline key code, remote RF card, & XYZ
                  <br /> are the three different ways that can be
                  <br /> used for managing employee access
                  <br /> based on role.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* second row */}
        <div className="col-12 my-5">
          <div className="row row-md-reverse">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex text-md-even align-items-center">
              <div>
                <h5 style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "800", fontSize: "1.7rem" }}>
                  Flexible
                </h5>
                <p style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "600", fontSize: "1.5rem" }}>
                  SecurePass offers flexibility in terms of
                  <br /> three different modes of employees'
                  <br /> access that can be used.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex align-items-center">
              <img
                src={`${cloudFrontOrigin}ac-cloud-2.svg`}
                className="cloud-images-thumbs-even"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
        {/* thirs row */}

        <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex align-items-center">
          <img
            src={`${cloudFrontOrigin}ac-cloud-3.svg`}
            className="cloud-images-thumbs-odd"
            alt=""
            srcset=""
          />
        </div>
        <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex text-md-odd align-items-center">
          <div>
            <h5 style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "800", fontSize: "1.7rem" }}>
              Fast
            </h5>
            <p style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "600", fontSize: "1.5rem" }}>
              The process of allowing access to
              <br /> employees from any of the three ways
              <br /> is fast through SecurePass
            </p>
          </div>
        </div>
        {/* fourth row */}
        <div className="col-12 ">
          <div className="row row-md-reverse">
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex text-md-even align-items-center">
              <div>
                <h5 style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "800", fontSize: "1.7rem" }}>
                  Reliable
                </h5>
                <p style={{ color: "rgba(255, 255, 255, 1)", fontWeight: "600", fontSize: "1.5rem" }}>
                  The SecurePass technology is reliable
                  <br /> in terms of data privacy of employees'
                  <br /> credentials
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xs-12 col-sm-12 p-5 d-flex align-items-center">
              <img
                src={`${cloudFrontOrigin}ac-cloud-4.svg`}
                className="cloud-images-thumbs-even"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* Benefits of Visitor Management */}
      <div className="row">
        <div className="col-12 mt-5 text-center">
          <h4 className="mt-5" style={{ color: "rgba(245, 25, 51, 1)", fontWeight: "700" }}>
            Benefits of Employee Access Control System
          </h4>
        </div>
        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5"></div>

        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center px-3">
            <img className="mb-3" src={`${cloudFrontOrigin}ac-benefit-1.svg`} alt="" srcset="" />
            <p className="benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
              Allows adoption of different forms of access control for different category of employees
            </p>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center px-3">
            <img className="mb-3" src={`${cloudFrontOrigin}ac-benefit-2.svg`} alt="" srcset="" />
            <p className="benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
              Time & attendance tracking of employees is an added advantage
            </p>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center px-3">
            <img className="mb-3" src={`${cloudFrontOrigin}ac-benefit-3.svg`} alt="" srcset="" />
            <p className="benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
              Fast processing of access denial or permission
            </p>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center px-3">
            <img className="mb-3" src={`${cloudFrontOrigin}ac-benefit-4.svg`} alt="" srcset="" />
            <p className="benifit-text-lp" style={{ color: "rgba(115, 115, 115, 1)" }}>
              Reliable solution that can be trusted for data privacy, giving confidence to employees
            </p>
          </div>
        </div>
        <div className="col-md-2 col-lg-2 col-xs-12 col-sm-12 mt-5"></div>
      </div>
      <div className="col-12 text-center">
        <h4 className="mt-5" style={{ color: "rgba(245, 25, 51, 1)", fontWeight: "700" }}>
          Testimonials
        </h4>
      </div>
      <div className="row p-5">
        <div className="col-12 text-center">
          <TestimonialCarausel />
        </div>
      </div>
      <div
        className="row"
        style={{
          backgroundImage: `url(${cloudFrontOrigin}bg-bottom.svg)`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          paddingBottom: "33vh",
          paddingTop: "20vh",
        }}
      >
        <div className="col-12 mt-5 pb-5">
          <div className="col-md-8 col-lg-8 col-xs-12 xol-sm-12 mx-auto d-flex justify-content-center">
            <div
              className="col-12 text-center"
              // style={{
              //   borderRadius: "15px",
              //   boxShadow: "0px 8px 8px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1)",
              // }}
            >
              <h4
                className="text-center my-5 font-weight-bold"
                style={{ color: "rgba(255, 255, 255, 1)" }}
              >
                Get in Touch
              </h4>
              <form onSubmit={handleSubmit2}>
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-xs-12 xol-sm-12">
                    <input
                      placeholder="First Name"
                      type="text"
                      name="firstName"
                      required
                      onChange={handleChange2}
                      className="dealer-input"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 xol-sm-12">
                    <input
                      placeholder="Last Name"
                      type="text"
                      name="lastName"
                      required
                      onChange={handleChange2}
                      className="dealer-input"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 xol-sm-12">
                    <input
                      placeholder="Email"
                      type="email"
                      name="email"
                      required
                      onChange={handleChange2}
                      className="dealer-input"
                    />
                  </div>
                  <div className="col-md-6 col-lg-6 col-xs-12 xol-sm-12">
                    <input
                      placeholder="Phone"
                      type="text"
                      name="phone"
                      required
                      onChange={handleChange2}
                      className="dealer-input"
                    />
                    {mobileError2 ? (
                      <p className="w-100 text-center">
                        <small className="text-danger font-weight-bold">{mobileError2}</small>
                      </p>
                    ) : null}
                  </div>
                  <div className="col-md-12 col-lg-12 col-xs-12 xol-sm-12">
                    <input
                      placeholder="Organization"
                      type="text"
                      name="companyName"
                      required
                      onChange={handleChange2}
                      className="dealer-input"
                    />
                  </div>

                  <div className="col-12">{loading2 ? <Loading /> : null}</div>
                  <div className="col-12 text-center my-y">
                    {result2 ? (
                      <p
                        style={{
                          textAlign: "center",
                          color: `${result2.success ? "#42C588" : "red"}`,
                        }}
                        className="my-2"
                      >
                        {result2.message}
                      </p>
                    ) : null}
                     <input className="button-white mt-5" type="submit" />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col-12 text-center text-white" style={{ backgroundColor: "#f24741" }}>
        <a className="text-white" href="https://www.freepik.com/vectors/watercolor">
          Watercolor vector created by coolvector - www.freepik.com
        </a>
      </div> */}
    </div>
  );
};

export default LandingPageEmployeeAccessControl;
