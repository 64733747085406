import React from 'react'

const GetaQuoteButton = ({title, onClick,customClass}) => {
    return (
      <button className={`getQuoteButton ${customClass ? customClass : ""}`} onClick={onClick}>
        {title}
      </button>
    );
}

export default GetaQuoteButton
