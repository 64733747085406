import React, { useState, useEffect } from "react";
import SuccessButton from "./SuccessButton";
import { PageForm, addCampaignParams } from "../utils/config";
import Loading from "./Loading";
import { Redirect } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import GetaQuoteButton from "./GetaQuoteButton";
const NewsLetterForm = ({ queryParams, setEmailUID, mountedOn, locationSearch }) => {
  const [formData, setFormData] = useState({ feature: {} });
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const handleChange = (e) => {
    if (mobileError) {
      setMobileError(false);
    }
    if (e.target.name.startsWith("feature")) {
      setFormData({
        ...formData,
        feature: {
          ...formData.feature,
          [e.target.value]: !formData.feature[e.target.value],
        },
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    if (e.target.name === "email") {
      setEmailUID(e.target.value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://us-central1-thesecurepass.cloudfunctions.net/api/submitNewsLetter`;

    if (`${formData.phone}`.length !== 10) {
      setMobileError("Please enter a valid Phone No");
    } else {
      setLoading(true);
      setMobileError(false);
      let features = Object.entries(formData.feature)
        .filter((v) => v[1])
        .map((v) => v[0])
        .join(" | ");
      console.log(features);

      let body = {
        data: {
          ...formData,
          feature: features,
          PageForm: PageForm + " contact form",
          sendMail: true,
        },
      };

      body = addCampaignParams(queryParams, body);

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((data) => {
          setLoading(false);
          setResult("Request Submitted");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setResult(null);
    }, 4000);
  }, [result]);

  return (
    <div className="container">
      {result === "Request Submitted" ? (
        <Redirect to={"/contact-thankyou" + locationSearch} />
      ) : null}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 text-center">
            <h5
              style={{ color: "#e53d54" }}
              className="my-4 text-center font-weight-bold"
            >
              Purchase Intent Form
            </h5>
          </div>

          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="firstName"
              onChange={handleChange}
              className="px-3  smallInput"
              placeholder="First Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="lastName"
              onChange={handleChange}
              type="text"
              className="px-3 smallInput"
              placeholder="Last Name*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="email"
              type="email"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Email*"
            />
          </div>
          <div className="col-md-6 col-sm-12 col-xs-12 m-0 p-2">
            <input
              required
              name="phone"
              type="number"
              className="px-3 smallInput"
              onChange={handleChange}
              placeholder="Phone*"
            />
            {mobileError ? (
              <p className="w-100 text-center">
                <small className="text-danger font-weight-bold">
                  {mobileError}
                </small>
              </p>
            ) : null}
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12 m-0 p-2">
            <select
              required
              name="noOfDoors"
              onChange={handleChange}
              className="px-3 smallInput"
            >
              <option>No Of Doors</option>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5+</option>
            </select>
          </div>

          <div className="col-md-12 col-sm-12 col-xs-12 m-0 p-2">
            <h5 className="font-weight-bold my-3">
              What are the features you are looking for?
            </h5>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12 m-0 p-2">
            <div className="row">
              <div className="col-12">
                <div class="form-check my-2">
                  <input
                    className="form-check-input "
                    type="checkbox"
                    name="feature1"
                    onChange={handleChange}
                    checked={formData.feature["Access Control System"]}
                    value="Access Control System"
                  />
                  <label className="form-check-label ml-3">
                    Access Control System
                  </label>
                </div>
                <div class="form-check my-2">
                  <input
                    className="form-check-input "
                    type="checkbox"
                    name="feature2"
                    onChange={handleChange}
                    checked={formData.feature["Time & Attendance"]}
                    value="Time & Attendance"
                  />
                  <label className="form-check-label ml-3">
                    Time & Attendance
                  </label>
                </div>
                <div class="form-check my-2">
                  <input
                    name="feature3"
                    className="form-check-input "
                    type="checkbox"
                    onChange={handleChange}
                    checked={formData.feature["Built-in Temperature Scanner"]}
                    value="Built-in Temperature Scanner
"
                  />
                  <label className="form-check-label ml-3">
                    Built-in Temperature Scanner
                  </label>
                </div>

                <div class="form-check my-2">
                  <input
                    name="feature4"
                    className="form-check-input "
                    type="checkbox"
                    onChange={handleChange}
                    checked={
                      formData.feature[
                        "Mobile & Cloud Based Remote Access Management"
                      ]
                    }
                    value="Mobile & Cloud Based Remote Access Management
"
                  />
                  <label className="form-check-label ml-3">
                    Mobile & Cloud Based Remote Access Management
                  </label>
                </div>
                <div class="form-check my-2">
                  <input
                    name="feature5"
                    className="form-check-input "
                    type="checkbox"
                    onChange={handleChange}
                    checked={formData.feature["Contactless Visitor Management"]}
                    value="Contactless Visitor Management
"
                  />
                  <label className="form-check-label ml-3">
                    Contactless Visitor Management
                  </label>
                </div>
                <div class="form-check my-2">
                  <input
                    name="feature6"
                    className="form-check-input "
                    type="checkbox"
                    onChange={handleChange}
                    checked={formData.feature["Cloud Integrations"]}
                    value="Cloud Integrations"
                  />
                  <label className="form-check-label ml-3">
                    Cloud Integrations
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12">{loading ? <Loading /> : null}</div>
          <div className="col-12">
            {result ? (
              <p
                style={{
                  textAlign: "center",
                  color: "#42C588",
                }}
                className="my-2"
              >
                {result}
              </p>
            ) : null}
          </div>
          {/* {mountedOn === "taboola-ads-campaign" ? (
            <div className="col-12 d-flex justify-content-center align-items-center my-2 m-0">
              <h6 className="">Try for Free for 6 Months.</h6>
            </div>
          ) : null} */}
          <div className="col-12  d-flex justify-content-center align-items-center my-3 m-0 p-2">
            {mountedOn === "taboola-ads-campaign" ||
            mountedOn === "Adcounty-Casestudy" ? (
              <>
                <h6
                  className="my-4 text-center font-weight-bold"
                  style={{ color: "#e53d54" }}
                >
                  Try free for first 6 months
                </h6>
                <BsArrowRight
                  className=" mx-2"
                  style={{ fontSize: "2rem", color: "#e53d54" }}
                />
              </>
            ) : null}
            {mountedOn === "taboola-ads-campaign" ? (
              <GetaQuoteButton type="submit" title="Submit" />
            ) : (
              <SuccessButton type="submit" title="Submit" />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default NewsLetterForm;
