import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import TagManager from "react-gtm-module";
const rootElement = document.getElementById("root");

const tagManagerArgs = {
  gtmId: "GTM-K2QP4H5",
};

TagManager.initialize(tagManagerArgs);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<App />, rootElement);
} else {
  ReactDOM.render(<App />, rootElement);
}
