import React from "react";
import ContactForm from "../components/ContactForm";
import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import SuccessButton from "../components/SuccessButton";
import GetaQuoteButton from "../components/GetaQuoteButton";
import { cloudFrontOrigin } from "../utils/config";
import NewsLetterForm from "../components/NewsLetterForm";

const NewsLetterPage = ({ setFlag, flag, queryParams, setEmailUID, locationSearch }) => {
  return (
    <div className="container pt-5">
      <Helmet>
        <title>
          Cloud Based Access Control System | Modern Access Management System -
          Download Case Studies
        </title>

        <meta
          name="description"
          content="SecurePass is a full-fledged cloud-based access control system that lets you manage access to your property from a centralized location. Call Us:  7760972082 "
        />

        <link rel="canonical" href="https://thesecurepass.com/contact" />
      </Helmet>
      <div className="row pt-5 d-flex justify-content-center align-items-center">
        <div className="col-12">
          <div className="row ">
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center py-3">
              <video
                src={`${cloudFrontOrigin}WhatsApp_Video_2020-12-05_at_15.19.47_x0v6ib_pzdo07.mp4`}
                autoPlay
                loop
                muted
                playsInline
                style={{ width: "65%" }}
              ></video>
            </div>
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center ">
              <NewsLetterForm
                mountedOn={"NewsLetter"}
                queryParams={queryParams}
                setEmailUID={setEmailUID}
                locationSearch={locationSearch}
              />
            </div>
            <div className="col-3  d-flex justify-content-center align-items-center my-2">
              <img
                style={{ width: "70%" }}
                src={`${cloudFrontOrigin}Group504.svg`}
                alt="contactless access control"
              />
            </div>
            <div className="col-9 my-2 d-flex  align-items-center">
              <p className="text-justify campaign-paragraph">
                <span className="font-weight-bold mb-3 campaign-paragraph ">
                  Touchless Entry
                </span>
                <br></br>
                Your mobile phone will act as a key to enter the premise without
                touching door handles to ensure safety.
              </p>
            </div>
            <div className="col-3 my-2 d-flex justify-content-center align-items-center">
              <img
                style={{ width: "70%" }}
                src={`${cloudFrontOrigin}output-onlinepngtools_qge0lp.png`}
                alt="contactless access control"
              />
            </div>
            <div className="col-9 my-2 d-flex  align-items-center">
              <p className="text-justify campaign-paragraph">
                <span className="font-weight-bold mb-3 campaign-paragraph">
                  Temperature Scanning
                </span>
                <br></br>
                Once the access is granted the system scans for body temperature
                and can send alert or restrict access if fever is detected
              </p>
            </div>

            <div className="col-3 my-2 d-flex justify-content-center align-items-center">
              <img
                style={{ width: "70%" }}
                src={`${cloudFrontOrigin}EDM_icon_3_qxzbyr.svg`}
                alt="contactless access control"
              />
            </div>
            <div className="col-9 my-2 d-flex align-items-center">
              <p className="text-justify campaign-paragraph ">
                <span className="font-weight-bold mb-3 campaign-paragraph">
                  Social Distancing
                </span>
                <br></br>
                SecurePass keeps track of number of people entering the building
                and can send alert/restrict access if total number of people
                inside exceeds a threshold.
              </p>
            </div>
            <div className="col-12">
              <h6
                style={{ color: "#e53d54" }}
                className=" text-center campaign-paragraph"
              >
                Want to add touchless tech to your door ?
              </h6>
            </div>
            <div className="col-12 text-center my-3">
              <Link to="/know-more">
                <GetaQuoteButton title={"Know More"} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsLetterPage;
