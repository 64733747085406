// http://localhost:3001/sendWelcomeEmail?userEmail=gauravve1@gmail.com

const pathname = window.location.pathname;
export const cloudFrontOrigin = "https://doswljnolqe2s.cloudfront.net/";
export const rootUrl = "https://tsptest.herokuapp.com";
// export const rootUrl = "http://localhost:3001";
export const PageForm =
  pathname === "/"
    ? "homepage"
    : pathname.startsWith("/contact")
    ? "contact"
    : pathname.startsWith("/getaquote")
    ? "getaquote"
    : pathname.startsWith("/download-casestudy")
    ? "download-casestudy"
    : pathname.startsWith("/dealer")
    ? "Dealer"
    : pathname.startsWith("/visitor-management-system")
    ? "VMS Landing Page"
    : pathname.startsWith("/access-control-system")
    ? "Access Control Landing Page"
    : pathname.startsWith("/attendance-management-system")
    ? "Attendance Management Landing Page"
    : pathname.startsWith("/remote-access-system")
    ? "Remote Access Landing Page"
    : "NONE";
export const validateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(mail)) return true;
  else return false;
};

export const addCampaignParams = (query, body) => {
  if (query) {
    const utm_source = query.get("utm_source");
    const utm_campaign = query.get("utm_campaign");
    const utm_medium = query.get("utm_medium");
    const utm_term = query.get("utm_term");
    if (utm_source) {
      body.data.utm_source = utm_source;
    }
    if (utm_campaign) {
      body.data.utm_campaign = utm_campaign;
    }
    if (utm_medium) {
      body.data.utm_medium = utm_medium;
    }
    if (utm_term) {
      body.data.utm_term = utm_term;
    }
  }
  return body;
};

export const blogsPillarTopicUrlsMap = {
  "best-contactless-access-control-system": "Best Contactless Access Control System",
  "social-distancing-solution": "Social Distancing",
  "best-visitor-management-system": "Visitor Management System",
  "contactless-access-safety": "Contactless access and safety",
  "access-control-system": "Access Control System",
  "card-access-control-system": "Card Access Control System",
  "Emerging Technology In Physical Security": "physical-security-solutions-technology",
};

export const getTopic = (k) => {
  for (const [key, value] of Object.entries(blogsPillarTopicUrlsMap)) {
    if (k === key) {
      return value;
    } else if (k === value) {
      return key;
    }
  }
};
