import React from "react";
import { cloudFrontOrigin } from "../utils/config";

const ClientsCarauselTileTemplate = ({ heading, icon, logo, useCase, testimony }) => {
  return (
    <div className="row h-100">
      <div className="col-md-10 col-lg-8 col-xs-12 col-sm-12 mx-auto text-center h-100">
        <div
          className="mx-auto h-100"
          style={{
            maxWidth: "800px",
            padding: "3rem",
            borderRadius: "16px",
            backgroundColor: "rgba(255, 255, 255, 1)",
            textAlign: "center",
          }}
        >
          <div className="row">
            <div className="col-12 ">
              <h5
                className="benifit-text-lp"
                style={{ color: "rgba(115, 115, 115, 1)", fontWeight: "800" }}
              >
                {heading}
              </h5>
            </div>
            <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex align-items-center justify-content-center pt-5">
              <img src={`${cloudFrontOrigin}${icon}`} style={{ width: "100px" }} />
            </div>
            <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 pt-5">
              <p className="text-left benifit-text-lp" style={{ color: "#4C515D" }}>
                {useCase}
              </p>
            </div>
            <div className="col-md-4 col-lg-4 col-xs-12 col-sm-12 d-flex align-items-center justify-content-center pt-5">
              <img src={`${cloudFrontOrigin}${logo}`} style={{ width: "150px" }} />
            </div>
            <div className="col-md-8 col-lg-8 col-xs-12 col-sm-12 d-flex align-items-center pt-5">
              <p className="text-left benifit-text-lp" style={{ color: "#4C515D" }}>
                {testimony}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientsCarauselTileTemplate;
