import { lazy } from "react";

import { cloudFrontOrigin } from "./utils/config";
const BlogEightWaysToEnsureSafety = lazy(() => import("./components/blogs/BlogEightWaysToEnsureSafety"));

const BlogEducationalInstitutions = lazy(() => import("./components/blogs/BlogEducationalInstitutions"));
const BlogcloudAccessManagement = lazy(() => import("./components/blogs/BlogcloudAccessManagement"));

const BlogRulesAndGuidlinesForRetailers = lazy(() =>
  import("./components/blogs/BlogRulesAndGuidlinesForRetailers")
);
const BlogGovernmentGuidlines = lazy(() => import("./components/blogs/BlogGovernmentGuidlines"));
const Blog5BreakthroughWaysToEnsure = lazy(() =>
  import("./components/blogs/Blog5BreakthroughWaysToEnsure")
);
const BlogaccessManagementControlSystemForCoWorkingSpace = lazy(() =>
  import("./components/blogs/BlogaccessManagementControlSystemForCoWorkingSpace")
);
const BlogaccessManagementControlSystemForRetailStores = lazy(() =>
  import("./components/blogs/BlogaccessManagementControlSystemForRetailStores")
);
const BlogaccessManagementControlSystemForRestaurants = lazy(() =>
  import("./components/blogs/BlogaccessManagementControlSystemForRestaurants")
);
const BlogaccessManagementControlSystemForHospitals = lazy(() =>
  import("./components/blogs/BlogaccessManagementControlSystemForHospitals")
);
const BlogaccessManagementControlSystemForSME = lazy(() =>
  import("./components/blogs/BlogaccessManagementControlSystemForSME")
);
const BlogVisitorManagementChecklist = lazy(() =>
  import("./components/blogs/BlogVisitorManagementChecklist")
);
const BlogDurgaPuja = lazy(() => import("./components/blogs/BlogDurgaPuja"));
const Dec1 = lazy(() => import("./components/blogs/Dec1"));
const Dec2 = lazy(() => import("./components/blogs/Dec2"));
const Dec3 = lazy(() => import("./components/blogs/Dec3"));
const Jan1 = lazy(() => import("./components/blogs/Jan1"));
const Jan2 = lazy(() => import("./components/blogs/Jan2"));
const Jan3 = lazy(() => import("./components/blogs/Jan3"));
const Jan4 = lazy(() => import("./components/blogs/Jan4"));
const Feb1 = lazy(() => import("./components/blogs/Feb1"));
const Feb2 = lazy(() => import("./components/blogs/Feb2"));
const Feb3 = lazy(() => import("./components/blogs/Feb3"));
const Mar1 = lazy(() => import("./components/blogs/Mar1"));
const Mar2 = lazy(() => import("./components/blogs/Mar2"));
const Mar3 = lazy(() => import("./components/blogs/Mar3"));
const Mar4 = lazy(() => import("./components/blogs/Mar4"));
const Apr1 = lazy(() => import("./components/blogs/Apr1"));
const Apr2 = lazy(() => import("./components/blogs/Apr2"));
const Apr3 = lazy(() => import("./components/blogs/Apr3"));
const May1 = lazy(() => import("./components/blogs/May1"));
const May2 = lazy(() => import("./components/blogs/May2"));
const May3 = lazy(() => import("./components/blogs/May3"));
const May4 = lazy(() => import("./components/blogs/May4"));
const June1 = lazy(() => import("./components/blogs/June1"));
const June2 = lazy(() => import("./components/blogs/June2"));
const June3 = lazy(() => import("./components/blogs/June3"));
const June4 = lazy(() => import("./components/blogs/June4"));
const July1 = lazy(() => import("./components/blogs/July1"));
const July2 = lazy(() => import("./components/blogs/July2"));
const July3 = lazy(() => import("./components/blogs/July3"));
const July4 = lazy(() => import("./components/blogs/July4"));
const Aug1 = lazy(() => import("./components/blogs/Aug1"));
const Aug2 = lazy(() => import("./components/blogs/Aug2"));
const Aug3 = lazy(() => import("./components/blogs/Aug3"));
const Aug4 = lazy(() => import("./components/blogs/Aug4"));
const Sep1 = lazy(() => import("./components/blogs/Sep1"));
const Sep2 = lazy(() => import("./components/blogs/Sep2"));
const Sep3 = lazy(() => import("./components/blogs/Sep3"));
const Sep4 = lazy(() => import("./components/blogs/Sep4"));
export const blogData = [
  {
    imgSrc: `${cloudFrontOrigin}sep-4-1.png`,
    date: "SEP 2021",
    template: Sep4,
    title: "What are the different types of biometrics? Get answer",
    url: "/blog/biometrics-in-access-control-systems",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Biometrics Technology | Access Control Systems",
    metaDescription:
      "Explore more about biometrics technology used in access control solutions. Types, advantages, disadvantages & more.",
    metaUrl: "access-control-system",
    metaKeywords:
      "access control, access control solutions, access management, visitor management, access technology",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}sep-4-2.png`,
    img3: `${cloudFrontOrigin}sep-4-3.png`,
    img4: `${cloudFrontOrigin}sep-4-4.png`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
    img4Alt: "",
    img4Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}sep-3-1.png`,
    date: "SEP 2021",
    template: Sep3,
    title: "Access control systems: types, advantages, & disadvantages ",
    url: "/blog/access-control-system",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Access control systems: types, advantages, & disadvantages",
    metaDescription:
      "Learn about the different types, advantages, & disadvantages of access control systems.",
    metaUrl: "access-control-system",
    metaKeywords:
      "Access control, access management, access control solutions, access management software ",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}sep-3-2.png`,
    img3: `${cloudFrontOrigin}sep-3-3.png`,
    img4: `${cloudFrontOrigin}sep-3-4.png`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
    img4Alt: "",
    img4Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}sep-2-1.jpg`,
    date: "SEP 2021",
    template: Sep2,
    title: "QR Codes: How to create QR code using google sheets, history & technology.",
    url: "/blog/qr-codes-technology",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "QR Code- History & Technology ",
    metaDescription: "Learn about QR Code and the technology used to make them along with history.  ",
    metaUrl: "qr-codes-technology",
    metaKeywords:
      "QR code, QR technology, technology, access control solutions, visitor management, visitor access ",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}sep-2-2.jpg`,
    img3: `${cloudFrontOrigin}sep-2-3.jpg`,
    img4: `${cloudFrontOrigin}sep-2-4.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
    img4Alt: "",
    img4Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}sep-1-1.jpg`,
    date: "SEP 2021",
    template: Sep1,
    title: "Smart cards: usage, technology, the process of making, & more.",
    url: "/blog/smart-card-access-management",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Smart Cards | Smart Access Management ",
    metaDescription:
      "Smart cards- learn how smart cards can be used in access control systems to manage access",
    metaUrl: "smart-card-access-management",
    metaKeywords:
      "smart cards, access control, access management, access control solution, smart card types",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}sep-1-2.jpg`,
    img3: `${cloudFrontOrigin}sep-1-3.jpg`,
    img4: `${cloudFrontOrigin}sep-1-4.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
    img4Alt: "",
    img4Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}aug-4-1.jpg`,
    date: "AUG 2021",
    template: Aug4,
    title: "Contactless temperature scanners- usage, working, & more",
    url: "/blog/contactless-temperature-scanners",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Contactless temperature scanner - SecurePass",
    metaDescription:
      "Contactless temperature scanner that allows body temperature scanning for both regular & infrequent visitors with in-built cloud storage & smart integrations.",
    metaUrl: "contactless-temperature-scanners",
    metaKeywords: "temperature scanner, contactless temperature scanning, temperature screening",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}aug-4-2.jpg`,
    img3: `${cloudFrontOrigin}aug-4-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}aug-3-1.jpg`,
    date: "AUG 2021",
    template: Aug3,
    title: "What is access control: authorization vs authentication vs management ",
    url: "/blog/access-control",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Access control: authorization vs authentication vs management",
    metaDescription:
      "Difference between access control authorization, authentication & management. Learn more about these access control terms.",
    metaUrl: "access-control",
    metaKeywords:
      "access control with cloud integration, securepass, access control system, cloud-based access control system",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}aug-3-2.jpg`,
    img3: `${cloudFrontOrigin}aug-3-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}aug-2-1.jpg`,
    date: "AUG 2021",
    template: Aug2,
    title: "What is a Visitor Management System?",
    url: "/blog/visitor-management-system-details",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Visitor management system - SecurePass",
    metaDescription:
      "Learn the usage, advantages & other details about the visitor management system with SecurePass. Cloud-based visitor management system with advanced technology.",
    metaUrl: "visitor-management-system-details",
    metaKeywords:
      "visitor management system, visitor management solution, visitor tracking solution, visitor management, securepass, visitor tracking system ",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}aug-2-2.jpg`,
    img3: `${cloudFrontOrigin}aug-2-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}aug-1-1.png`,
    date: "AUG 2021",
    template: Aug1,
    title: "Access control solution: meaning, usage, types, & more",
    url: "/blog/access-control-solution",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Access control solution- meaning & usage - SecurePass ",
    metaDescription:
      "What is an access control solution? Learn the meaning, usage & advantages here. Cloud-based technology with smart integrations.",
    metaUrl: "access-control-solution",
    metaKeywords:
      "access control with cloud integration, securepass, access control system, cloud-based access control system",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}aug-1-2.png`,
    img3: `${cloudFrontOrigin}aug-1-3.jpg`,
    img4: `${cloudFrontOrigin}aug-1-4.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
    img4Alt: "",
    img4Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}july-4-1.jpg`,
    date: "JULY 2021",
    template: July4,
    title: "Support: After sales support with SecurePass",
    url: "/blog/after-sales-support-offered-by-securepass",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "After Sales Support | SecurePass",
    metaDescription:
      "After Sales Support - SecurePass offeres best customer service with regular updates, better usage of the dashboard, repairing a fault in accessories, & more",
    metaUrl: "after-sales-support-offered-by-securepass",
    metaKeywords:
      "customer support access control, customer service, access control solution, sales support solution",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}july-4-2.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}july-3-1.jpg`,
    date: "JULY 2021",
    template: July3,
    title: "Cloud Integration: Simplicity of storage & ease of access offered by SecurePass",
    url: "/blog/securepass-cloud-integration",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Access Control System with Cloud Integration - SecurePass",
    metaDescription:
      "Access control solution with cloud integration that allows admins to access information stored from a single dashboard from any location and device. ",
    metaUrl: "securepass-cloud-integration",
    metaKeywords:
      "access control with cloude integraation, securepass, access control system, cloud based accesss control system",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}july-3-2.jpg`,
    img3: `${cloudFrontOrigin}july-3-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}july-2-1.jpg`,
    date: "JULY 2021",
    template: July2,
    title: "Visitor management system: usage, advantages & more with SecurePass",
    url: "/blog/visitor-management-system-features-advantages",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Visitor Management System | Digital Visitor Experience",
    metaDescription:
      "Visitor Management System - Learn how to track visitors with a modern cloud-based access control system that comes with an in-built VMS",
    metaUrl: "visitor-management-system-features-advantages",
    metaKeywords:
      "visitor management system, visitor management solution, visitor tracking solution, visitor management, securepass, visitor tracking system",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}july-2-2.jpg`,
    img3: `${cloudFrontOrigin}july-2-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}july-1-1.jpg`,
    date: "JULY 2021",
    template: July1,
    title: "Employee Attendance Analytics: How’s & Why’s With SecurePass",
    url: "/blog/employee-attendance-analytics",
    subTopic: "",
    pillarTopic: "",
    metaTitle: "Employee Attendance Analytics | Tracking Employee Attendance ",
    metaDescription:
      "Employee attendance management - Learn how SecurePass helps in tracking employee attendance & analytics on desktop or mobile seamlessly.",
    metaUrl: "employee-attendance-analytics",
    metaKeywords:
      "employee attendance system, employee attendance system, employee attendance management, employee attendance analytics, employee tracking solution, Securepass",
    focusKeywords: "",
    img2: `${cloudFrontOrigin}july-1-2.jpg`,
    img3: `${cloudFrontOrigin}july-1-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}june-4-1.jpg`,
    date: "JUNE 2021",
    template: June4,
    title: "Keyless Entry System in Access Control Solution",
    url: "/blog/keyless-entry-system",
    subTopic: "Keyless Entry System in access control solution",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "Keyless Entry System - Access Control Solution | SecurePass",
    metaDescription:
      "Figure out everything related to the Keyless Entry System in Access Control Solution in SecurePass Blog. ",
    metaUrl: "keyless-entry-system",
    metaKeywords:
      "keyless entry system, keyless access control system, access control solution, latest access control system",
    focusKeywords: "keyless access control system",
    img2: `${cloudFrontOrigin}june-4-2.jpg`,
    img3: `${cloudFrontOrigin}june-4-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}june-3-1.jpg`,
    date: "JUNE 2021",
    template: June3,
    title: "7 Interesting Facts About Biometrics - Access Control Solutions",
    url: "/blog/biometric-access-control",
    subTopic: "Biometric Access Control Technology",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "Facts About Biometrics Access Control Solutions | SecurePass",
    metaDescription:
      "Learn interesting facts about Biometric Access Control Systems which are also used as keyless access control solution.",
    metaUrl: "biometric-access-control",
    metaKeywords:
      "biometric access control system, access control systems, best access control system, biometric access control, biometric access control technology India,",
    focusKeywords: "biometric access control",
    img2: `${cloudFrontOrigin}june-3-2.jpg`,
    img3: `${cloudFrontOrigin}june-3-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}june-2-1.jpg`,
    date: "JUNE 2021",
    template: June2,
    title: "RFID v/s NFC Access Control Technology",
    url: "/blog/rfid-versus-nfc-access-control-technology",
    subTopic: "RFID v/s NFC Access Control Technology",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "RFID v/s NFC Access Control Technology | SecurePass",
    metaDescription:
      "Know the working, usability, & benefits of Radio Frequency Identification (RFID) & Near Field Communication (NFC) Access Control Technology.",
    metaUrl: "rfid-versus-nfc-access-control-technology",
    metaKeywords:
      "RFID access control, NFC access control, types of access control system, access control system",
    focusKeywords: "access control system",
    img2: `${cloudFrontOrigin}june-2-2.jpg`,
    img3: `${cloudFrontOrigin}june-2-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}june-1-1.jpg`,
    date: "JUNE 2021",
    template: June1,
    title: "5 Reasons Why Access Control Solutions Are Useful At Residential Apartments",
    url: "/blog/residential-access-control-system-benefits",
    subTopic: "Access Control Solution Benefits At Residential Appartments",
    pillarTopic: "Access Control System",
    metaTitle: "Access Control System Benefits For Residential Apartments",
    metaDescription:
      "Find out 5 ways in which Access Control System benefits Residental Appartments or Buildings on SecurePass blog.",
    metaUrl: "residential-access-control-system-benefits",
    metaKeywords:
      "access control system, access control solution, contactless access control system, access solution for residents, building access control system ",
    focusKeywords: "building access control system",
    img2: `${cloudFrontOrigin}june-1-2.jpg`,
    img3: `${cloudFrontOrigin}june-1-3.jpg`,
    imgSrcAlt: "",
    imgSrcCaption: "",
    img2Alt: "",
    img2Caption: "",
    img3Alt: "",
    img3Caption: "",
  },
  {
    imgSrc: `${cloudFrontOrigin}qr-code-infographic.jpg`,
    date: "MAY 2021",
    template: May4,
    title: "8 Interesting facts about QR Codes ",
    url: "/blog/interesting-qr-codes-facts",
    subTopic: "QR Code Facts",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "QR Codes Interesting Facts | SecurePass",
    metaDescription:
      "Read about some really interesting facts about the technology of QR Code Readers on the SecurePass blog.",
    metaUrl: "interesting-qr-codes-facts",
    metaKeywords: "interesting QR codes facts, QR codes facts, QR code reader,",
    focusKeywords: "interesting QR codes facts",
    img2: `${cloudFrontOrigin}customizable-qr-codes.jpg`,
    img3: `${cloudFrontOrigin}qr-codes-info.jpg`,
    imgSrcCaption: "QR Code use cases Infographic",
    imgSrcAlt: "An infographic showing uses of QR Code.",
    img2Caption: "Customizable QR Codes",
    img2Alt: "QR codes can be customized easily.",
    img3Caption: "QR Codes Info",
    img3Alt: "A QR Code being used to provide information by scanning it.",
  },
  {
    imgSrc: `${cloudFrontOrigin}automatic-door-technologies.jpg`,
    date: "MAY 2021",
    template: May3,
    title: "Automatic Doors & The Technology Used ",
    subTopic: "Automatic Door Opening & Sensor Technology Used ",
    pillarTopic: "Emerging Technology In Physical Security",
    url: "/blog/automatic-door-opening-technology",
    metaTitle: "Automatic Door Opening Sensor Systems",
    metaDescription:
      "Understand the mechanism behind the automatic door opening sensor systems. Read more on SecurePass blog.",
    metaUrl: "automatic-door-opening-technology",
    metaKeywords:
      "automatic door opening sensors, automatic door opening technology, automatic door opener, automatic door opening, smart automatic door opener, ",
    focusKeywords: "automatic door opening",
    img2: `${cloudFrontOrigin}sensor-system-types.jpg`,
    img3: `${cloudFrontOrigin}turnstile-gates.jpg`,
    imgSrcCaption: "Automatic Door Technologies",
    imgSrcAlt: "Automated entry or exit door in an office.",
    img2Caption: "Sensor System Types",
    img2Alt:
      "Types of Sensor systems: pressure/weight sensor system, infrared sensor system, motion detector sensor system",
    img3Caption: "Turnstile Gates for office entry/exit",
    img3Alt: "Turnstile Gates technology being used for office entry/exit by employees.",
  },
  {
    imgSrc: `${cloudFrontOrigin}qr-code-usage.jpg`,
    date: "MAY 2021",
    template: May2,
    title: "What is a QR Code- Its Working, Uses, Advantages. & Disadvantages  ",
    url: "/blog/qr-code-working-usage",
    subTopic: "QR Code - Working, Usage | Pros & Cons",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "QR Codes - Working, Usage, Pros & Cons | SecurePass",
    metaDescription:
      "Learn about QR Code working, its usage, advantages, disadvantages and more on the SecurePass blog.",
    metaUrl: "qr-code-working-usage",
    metaKeywords: "QR code reader, QR codes evolution, benefits of QR code reader",
    focusKeywords: "QR Code reader",
    img2: `${cloudFrontOrigin}qr-codes-uses.jpg`,
    img3: `${cloudFrontOrigin}qr-code-application.jpg`,
    imgSrcCaption: "QR codes usage for VMS",
    imgSrcAlt: "SecurePass used for visitor management by scanning QR code  in office.",
    img2Caption: "QR Codes Uses Infographic",
    img2Alt:
      "Infographic describing uses of QR Code such as website, contact, statistics, payment, content, and menu.",
    img3Caption: "Common Applications Of QR Codes",
    img3Alt:
      "Different applications of QR Codes like website URL, Application downloads, add locations, access contol system, networking, etc.",
  },
  {
    imgSrc: `${cloudFrontOrigin}contactless-securepass.png`,
    date: "MAY 2021",
    template: May1,
    title: "The Growth of Contactless Technology in India in the Post Covid-19 World ",
    url: "/blog/contactless-access-control-technology-shift",
    subTopic: "Contactless Technology Growth In India Post COVID",
    pillarTopic: "Emerging Technology In Physical Security",
    metaTitle: "Contactless Technology Growth Post COVID - India",
    metaDescription:
      "Read about the technological shift to Contactless Access Control in India post COVD on SecurePass Blog.",
    metaUrl: "contactless-access-control-technology-shift",
    metaKeywords:
      "contactless access control system, access control system, best access control system, touchless access control, contactless access control technology, contactless technology India,",
    focusKeywords: "contactless access control technology",
    img2: `${cloudFrontOrigin}contactless-payments.png`,
    img3: `${cloudFrontOrigin}contactless-temperature-scanner.png`,
    imgSrcCaption: "Contactless Technology Growth ",
    imgSrcAlt: "SecurePass app used by an employee for contactless door entry in office.",
    img2Caption: "Contactless payments over card payment",
    img2Alt: "Contactless payments for safety.",
    img3Caption: "Contactless Temperature Scanner for Check-ins",
    img3Alt: "SecurePass for contactless check-ins using contactless temperature scanner",
  },
  {
    imgSrc: `${cloudFrontOrigin}remote-access-management.png`,
    date: "APR 2021",
    template: Apr3,
    title: "5 Reasons To Choose Modern & Contactless Access Control Systems",
    url: "/blog/choosing-modern-contactless-access-control-system",
    subTopic: "Modern & Contactless Access Control System",
    pillarTopic: "Best Contactless Access Control System",
    metaTitle: "Choosing Modern & Contactless Access Control System- 5 Reasons",
    metaDescription:
      "Read about the major 5 reasons to choose a Modern & Contactless Access Control System in the 21st century on SecurePass blog.",
    metaUrl: "choosing-modern-contactless-access-control-system",
    metaKeywords:
      "modern contactless access control system, contactless access control system for modern world, advanced contactless access solution, contactless access control system, access control system for modern world",
    focusKeywords: "modern contactless access control system",
    img2: `${cloudFrontOrigin}contactless-door-access.png`,
    img3: `${cloudFrontOrigin}securepass-smart-integrations.png`,
    img4: `${cloudFrontOrigin}securepass-regular-reports.png`,
    imgSrcCaption: "Remote Access Management by SecurePass",
    imgSrcAlt:
      "SecurePass offers remote access management using a single dashboard which can be accessed via smartphone or desktop.",
    img2Caption: "Contactless door access control ",
    img2Alt: "Smartphone based contactless door access using SecurePass.",
    img3Caption: "Smart integrations with HRMS, VMS or payroll system.",
    img3Alt:
      "SecurePass offers smart integrations with HR management software, Visitor Management System or payroll system.",
    img4Caption: "Get regular reports with SecurePass ",
    img4Alt:
      "SecurePass, contactless cloud-based access control system provides regular reports for visitor and employess entry and exit time.",
  },
  {
    imgSrc: `${cloudFrontOrigin}mandatory-access-control.png`,
    date: "APR 2021",
    template: Apr2,
    title: "Mandatory Access Control System: Use, Advantages, & Disadvantages",
    url: "/blog/mandatory-access-control-system",
    pillarTopic: "Access Control System",
    subTopic: "Mandatory Access Control System | Usage, Pros & Cons",
    metaTitle: "Mandatory Access Control System - Usage, Pros & Cons | Securepass",
    metaDescription:
      "Learn about what is Mandatory Access Control System, its usage, advantages, and disadvantages & more on SecurePass blog.",
    metaUrl: "mandatory-access-control-system",
    metaKeywords: "mandatory access control system, access control system, access control solution",
    focusKeywords: "mandatory access control system",
    img2: `${cloudFrontOrigin}mandatory-access-control-advantages.png`,
    img3: `${cloudFrontOrigin}mandatory-access-control-disadvantages.png`,
    imgSrcCaption: "Mandatory Access Control (MAC)",
    imgSrcAlt:
      "Mandatory Access Control (MAC) works on criteria defined by a system administrator where each possible entrant is categorized under a special category. ",
    img2Caption: "Mandatory Access Control (MAC) Advantages",
    img2Alt:
      "Three advantages of Mandatory Access Control (MAC) are high level data protection, centralized information and  Privacy.",
    img3Caption: "Mandatory Access Control (MAC) Disadvantages",
    img3Alt:
      "Three disadvantage of Mandatory Access Control (MAC) are careful setting up process, regular update required and lack of flexibility.",
  },
  {
    imgSrc: `${cloudFrontOrigin}discreationary-access-control.png`,
    date: "APR 2021",
    template: Apr1,
    title: "Discretionary Access Control: Uses, Advantages, Disadvantages & More",
    url: "/blog/discretionary-access-control-system",
    pillarTopic: "Access Control System",
    subTopic: "Discretionary Access Control System | Usage, Pros & Cons",
    metaTitle: "Discretionary Access Control System - Usage, Pros & Cons",
    metaDescription:
      "Learn about what is Discretionary Access Control System, its usage, advantages, and disadvantages & more on SecurePass blog.",
    metaUrl: "discretionary-access-control-system",
    metaKeywords:
      "discretionary access control system, advantages of discretionary access control system, access control system, contactless access control system",
    focusKeywords: "discretionary access control system",
    img2: `${cloudFrontOrigin}discreationary-access-control-advantages.png`,
    img3: `${cloudFrontOrigin}discreationary-access-control-disadvantages.png`,
    imgSrcCaption: "Discreationary Access Control (DAC)",
    imgSrcAlt: "Explanation of Discreationary Access Control (DAC) working in an infographic.",
    img2Caption: "Discreationary Access Control (DAC) Advantages",
    img2Alt:
      "Advantages of Discreationary Access Control (DAC) are User-friendly, flexibility and easy maintenance for administration.",
    img3Caption: "Discreationary Access Control (DAC) Disadvantages",
    img3Alt:
      "Two major disadvantages of Discreationary Access Control (DAC) are less secure system and difficult to track data.",
  },
  {
    imgSrc: `${cloudFrontOrigin}role-based-access-control.jpg`,
    date: "MAR 2021",
    template: Mar4,
    title:
      "Role-Based Access Control Management System-Usage, Advantages, Limitations & More-SecurePass",
    url: "/blog/role-based-access-control-system",
    metaTitle: "Role-Based Access Control Management System",
    pillarTopic: "Access Control System",
    subTopic: "Role Based Access Control System | Pros & Cons",
    metaDescription:
      "Learn about what is Role-Based Access Control Management System, its usage, advantages, limitations & more",
    metaUrl: "role-based-access-control-system",
    metaKeywords:
      "Role-Based Access Control System, access control system, access control solution, contactless access control system",
    focusKeywords: "Role-Based Access Control System",
    img2: `${cloudFrontOrigin}role-based-access-control-uses.jpg`,
    img3: `${cloudFrontOrigin}role-based-access-control-advantages.jpg`,
    img4: `${cloudFrontOrigin}role-based-access-control-disadvantages.jpg`,
    imgSrcCaption: "Role Based Access Control System",
    imgSrcAlt:
      "Role Based Access system allows a company to control access of an area or information depending on employee's position.",
    img2Caption: "Role Based Access Control System Management Uses",
    img2Alt:
      "Major uses of Role Based Access Control System are in banking sector, warehouses and commercial or residential apartments.",
    img3Caption: "Role Based Access Control System Management Advantages",
    img3Alt:
      "Four major advantages fo Role Based Access Control System Management are efficiency, security, time-saving and record keeping.",
    img4Caption: "Role Based Access Control System Management Disadvantages",
    img4Alt:
      "Disadvantages of Role Based Access Control System includes management limited to role and complexity for large organization.",
  },
  {
    imgSrc: `${cloudFrontOrigin}mandatory-access-management-image.jpg`,
    date: "MAR 2021",
    template: Mar3,
    pillarTopic: "Access Control System",
    subTopic: "Types of Access Control System | Pros & Cons",
    title: "Types of Access Control Systems Management: Pros & Cons | SecurePass",
    url: "/blog/access-control-system-types",
    metaTitle: "Access Control System Types, Pros & Cons | SecurePass",
    metaDescription:
      "Types Of Access Control System - Mandatory Access Control, Discretionary Access Control, Role-Based Access Control and their Pros & Cons.",
    metaUrl: "access-control-system-types",
    metaKeywords:
      "access control system, access control solution, contactless access control system, types of access control system, access control system history",
    focusKeywords: "types of access control system",
    img2: `${cloudFrontOrigin}discreationary-access-control-image.jpg`,
    img3: `${cloudFrontOrigin}role-based-access-control-image.jpg`,
    imgSrcCaption: "Mandatory Access Management (MAC) Image",
    imgSrcAlt: "Mandatory Access Management (MAC) and its working explained in an infographic.",
    img2Caption: "Discreationary Access Control (DAC) Image",
    img2Alt: "Discreationary Access Control (DAC) and its working explained in an infographic.",
    img3Caption: "Role Based Access Control System Management Image",
    img3Alt: "Role Based Access Control System Management and its working explained in an infographic.",
  },
  {
    imgSrc: `${cloudFrontOrigin}modern-office-space.jpg`,
    date: "MAR 2021",
    template: Mar2,
    title: "5 Crucial Advantages of Contactless & Advanced Access Control Solutions in the Modern World",
    url: "/blog/contactless-access-control-system-advantages",
    metaTitle: "Modern Contactless Access Control System Advantages ",
    metaDescription:
      "5 Crucial Advantages of Advanced Contactless Access Control System in the Modern World",
    metaUrl: "contactless-access-control-system-advantages",
    metaKeywords:
      "contactless access control system, advanced contactless access solution, benefits of contactless access control system, access control system for modern world",
    focusKeywords: "contactless access control system",
    subTopic: "Contactless Access Control System Advantages",
    pillarTopic: "Best Contactless Access Control System",
    img2: `${cloudFrontOrigin}access-control-device-for-office.jpg`,
    img3: `${cloudFrontOrigin}securepass-smart-integration-infographic.jpg`,
    img4: `${cloudFrontOrigin}securepass-access-control-dashboard.png`,
    imgSrcCaption: "Modern Contactless Access Office Space Image",
    imgSrcAlt: "Contactless Access Control System for Modern & Advanced Office spaces",
    img2Caption: "SecurePass Contactless Door Entry In Office",
    img2Alt:
      "SecurePass on the office wall for contactless door access with built-in temperature scanner",
    img3Caption: "Image of Smart Integration With SecurePass",
    img3Alt:
      "SecurePass Modern-day access control solutions allows smart integrations of HR software & visitor management systems.",
    img4Caption: "SecurePass Dashboard View On Desktop & Mobile",
    img4Alt:
      "SecurePass is a cloud-based access control system which offers dashboard access from mobile app alongwith desktop.",
  },
  {
    imgSrc: `${cloudFrontOrigin}securepass-office-access-control-device.png`,
    date: "MAR 2021",
    template: Mar1,
    subTopic: "SecurePass One-Stop Access Control Solution",
    pillarTopic: "Best Contactless Access Control System",
    title:
      "SecurePass-One Stop Solution For Complete Access Security Solutions- Employees Management & Safety",
    url: "/blog/employees-one-stop-security-solution-access-control-system",
    metaTitle: "SecurePass- Employees Safest Complete Access Control System",
    metaDescription:
      "Securepass - The safest one-stop security solution for the employees with a complete contactless access control system. ",
    metaUrl: "employees-one-stop-security-solution-access-control-system",
    metaKeywords:
      "access control system, employees security solution, employee access control system, office one stop access control solution",
    focusKeywords: "Employee Access Control System",
    img2: `${cloudFrontOrigin}smart-card-based-access-to-office.png`,
    img3: `${cloudFrontOrigin}securepass-access-control-dashboard.png`,
    img3Caption: "SecurePass Dashboard View On Desktop & Mobile",
    img3Alt:
      "SecurePass is a cloud-based access control system which offers dashboard access from mobile app alongwith desktop.",
    imgSrcCaption: "Office Access Control System",
    imgSrcAlt:
      "Modern Contactless Access Control System in offices for employees management and safety.",
    img2Caption: "Card Based Access Control System For Office",
    img2Alt:
      "SecurePass offers smart card based door access along with its touchless access control system",
  },
  {
    imgSrc: `${cloudFrontOrigin}securepass-cloud-based-vms.jpg`,
    date: "FEB 2021",
    template: Feb3,
    title:
      "Choose The Right Visitor Management System For Your Office: Look For These 5 Key Aspects- SecurePass",
    url: "/blog/choosing-right-office-visitor-management-system",
    subTopic: "Right Visitor Management System For Office",
    pillarTopic: "Visitor Management System",
    metaTitle: "Choosing The Right Office Visitor Management System | SecurePass",
    metaDescription:
      "Look for these 5 Key Aspects while choosing the best visitor management system for your office to ensure the safety of your employees post-COVID.",
    metaUrl: "choosing-right-office-visitor-management-system",
    metaKeywords:
      "visitor management system to ensure safety, visitor management system checklists, vms, contactless visitor management system, Visitor Management System Ensure Safety, visitor management system for office",
    focusKeywords: "visitor management system for office",
    img2: `${cloudFrontOrigin}securepass-otp-based-access.jpg`,
    img3: `${cloudFrontOrigin}securepass-real-time-visitor-data-reports.jpg`,
    img4: `${cloudFrontOrigin}optimum-data-storage-by-securepass.jpg`,
    imgSrcCaption: "Cloud Based Visitor Management System (VMS)",
    imgSrcAlt:
      "SecurePass offers cloud-based visitor management system which is the need of the hour for modern workplaces. ",
    img2Caption: "Code Based Access for Higher Security",
    img2Alt: "SecurePass offers higher security with OTP based visitor access management.",
    img3Caption: "Real-time Visitor Data Reports",
    img3Alt: "SecurePass provides real-time visitor data sheets that can be downloaded",
    img4Caption: "Optimum Data Storage Image ",
    img4Alt:
      "A cloud-based visitor management system from trustable partner solves optimum data storage issue.",
  },
  {
    imgSrc: `${cloudFrontOrigin}securepass-built-in-temperature-scanner.jpg`,
    date: "FEB 2021",
    template: Feb2,
    title: "Ensure Safe Return To Office For Employees By Following These 5 Steps",
    url: "/blog/post-COVID-workplace-safety-employees-return",
    subTopic: "Post COVID Workplace Safety",
    pillarTopic: "Contactless access and safety",
    metaTitle: "Post COVID Workplace Safety | Employees Return Safety Measures",
    metaDescription:
      "Follow these 5 measures to ensure safe returns of your employees at workplace post COVID once lockdown lifts.",
    metaUrl: "post-COVID-workplace-safety-employees-return",
    metaKeywords:
      "post COVID workplace safety, employees safe return to office, office workplace safety of employees",
    focusKeywords: "post COVID workplace safety",
    img2: `${cloudFrontOrigin}securepass-contactless-door-entry-gif.gif`,
    img3: `${cloudFrontOrigin}covid-19-precautions-workstations.jpg`,
    img4: `${cloudFrontOrigin}mobile-based-touchless-door-entry.jpg`,
    img5: `${cloudFrontOrigin}sanitiser-bottle-stand.jpg`,
    imgSrcCaption: "Girl scanning temperature using SecurePass",
    imgSrcAlt:
      "A girl showing wrist to SecurePass access control for temparature scanning and contactless door entry.",
    img2Caption: "SecurePass Contactless door entry gif",
    img2Alt: "An employee using SecurePass for contactless door entry in meeting room in an office.",
    img3Caption: "COVID-19 workstation precautions infographic",
    img3Alt: "An infographic of COVID-19 precautions with DO's and Dont's at workstations.",
    img4Caption: "Mobile based touchless door entry using SecurePass",
    img4Alt: "A person using SecurePass mobile-based touchless door entry system.",
    img5Caption: "sanitiser bottle stand image",
    img5Alt:
      "An image of senitiser bottle with foot touch stand to avoid touching and proper santising.",
  },
  {
    imgSrc: `${cloudFrontOrigin}smart-card-reader.jpg`,
    date: "FEB 2021",
    template: Feb1,
    title: "Smart Card Readers- What Do They Do & How Do They Work?",
    url: "/blog/smart-card-reader-access-control-system",
    pillarTopic: "Card Access Control System",
    subTopic: "Smart Card Reader in Access Control Solutions | Advantages & Disadvantages",
    metaTitle: "Smart Card Reader | Contactless Smart Card Based Access Control",
    metaDescription:
      "Smart card based access conreol solution is the modern way of ensuring contactless access control. Read more to know its usage and benefits.",
    metaUrl: "smart-card-reader-access-control-system",
    metaKeywords:
      "smart card reader, smart card reader based access control, contactless access control system, door access control system",
    focusKeywords: "smart card reader access control system",
    img2: `${cloudFrontOrigin}smart-card-image.jpg`,
    img3: `${cloudFrontOrigin}smart-card-benefits.jpg`,
    img4: `${cloudFrontOrigin}payment-using-smart-card.jpg`,
    img5: `${cloudFrontOrigin}rfid-card-based-door-entry.jpg`,
    imgSrcCaption: "smart card reader picture",
    imgSrcAlt: "An image of a smart card reader machine.",
    img2Caption: "smart card image",
    img2Alt:
      "An image of a smart card which looks like a regular plastic card but is enables by a magenetic technology.",
    img3Caption: "smart card advantages infographic",
    img3Alt:
      "Advantages of a smart card explained in an infographic are security, adaptability and lerger protected memory.",
    img4Caption: "payment made using smart card infographic",
    img4Alt: "An infographic showing the payment dopne and approved using a smart card.",
    img5Caption: "RFID card based door entry with SecurePass",
    img5Alt:
      "A resident using SecurePass for RFID card based door entry for contactless and safe access to the building.",
  },
  {
    imgSrc: `${cloudFrontOrigin}weigand-technology.png`,
    date: "JAN 2021",
    template: Jan4,
    title: "Wiegand Card Reader in Access Control Uses & Safety in Access Control Systems",
    subTopic: "Wigend Card Reader in Access Control System | Usage & Safety",
    pillarTopic: "Card Access Control System",
    url: "/blog/wigend-card-reader-access-control-system",
    metaTitle: "Wigend Card Reader in Access Control System | Usage & Safety",
    metaDescription:
      "Wigend card reader access control system - Learn about the wigend card reader technology, and how the industry is using it for their extra safety.",
    metaUrl: "wigend-card-reader-access-control-system",
    metaKeywords:
      "wigend card based access solution, wigend access card, wigend card entry, wigend card access control system, types of wigend card access control",
    focusKeywords: "wigend card reader access control system",
    img2: `${cloudFrontOrigin}weigand-access-control.png`,
    img3: `${cloudFrontOrigin}weigand-card.png`,
    img4: `${cloudFrontOrigin}26-bit-weigand-format.png`,
    imgSrcCaption: "weigand technology device image",
    imgSrcAlt: "An infographic showing weigand technology.",
    img2Caption: "Weigand technology used in an access control system",
    img2Alt: "An infographic showing weigand technology used for an access control system.",
    img3Caption: "weigand card image",
    img3Alt: "An image of a weigand card which is just like a regular card with a weigand strip.",
    img4Caption: "26 bit weigand format infographic",
    img4Alt: "An infographic describing the 26 bit weigand format protocol on the card.",
  },
  {
    imgSrc: `${cloudFrontOrigin}magnetic-strip-card-reader.jpg`,
    date: "JAN 2021",
    template: Jan3,
    title: "Magnetic stripe card reader- uses, types, advantage, access control systems and more",
    pillarTopic: "Card Access Control System",
    subTopic: "Magnetic Stripe Card Reader | Magnetic Strip Card Access Control",
    url: "/blog/magentic-strip-card-reader-access-control-system",
    metaTitle: "Magnetic Stripe Card Reader | Magnetic Strip Card Access Control",
    metaDescription:
      "Learn about magnetic strip card reader usage, types & how industry using it for their safety",
    metaUrl: "magentic-strip-card-reader-access-control-system",
    metaKeywords:
      "magnetic strip card based access solution, magnetic strip access card, magnetic strip card entry, magnetic strip card access control system, types of magnetic strip card access control",
    focusKeywords: "magnetic strip card reader access control system",
    img2: `${cloudFrontOrigin}swipe-card-magnetic-reader.jpg`,
    img3: `${cloudFrontOrigin}insert-magnetic-strip-card.jpg`,
    img4: `${cloudFrontOrigin}magnetic-strip-card-working.jpg`,
    img5: `${cloudFrontOrigin}magnetic-strip-card-advantages-disadvantages.jpg`,
    imgSrcCaption: "magnetic strip card reader image",
    imgSrcAlt: "An image of a magnetic strip card reader.",
    img2Caption: "swipe card magnetic reader image",
    img2Alt: "A swipe card with magnetic strip used for cashless payment.",
    img3Caption: "insert magnetic strip card",
    img3Alt: "An insert acrd with magnetic strip used for cashless payment",
    img4Caption: "magnetic strip card working infographic",
    img4Alt: "An infographic explaining the working of a magnetic strip card reader",
    img5Caption: "magnetic strip card advantages disadvantages infographic ",
    img5Alt:
      "An infographic explaining the advantages and disadvantages of a magnetic strip card reader.",
  },
  {
    imgSrc: `${cloudFrontOrigin}proximity-card.jpg`,
    date: "JAN 2021",
    template: Jan2,
    title: "Proximity card reader- Working, Uses, Access Control, Pros & Cons",
    subTopic: "Proximity card reader | Proimity Card Access Control | Pros & Cons",
    pillarTopic: "Card Access Control System",
    url: "/blog/proximity-card-reader-access-control-system",
    metaTitle: "Proximity card reader | Proimity Card Access Control | Pros & Cons",
    metaDescription:
      "Proximity card access control system -  Learn all about the Proximity card reader for safety & its pros and cons as an access control system.",
    metaUrl: "proximity-card-reader-access-control-system",
    metaKeywords:
      "proximity card based access solution, proximity access card, proximity card entry, proximity card access control system, types of card access control",
    focusKeywords: "proximity card reader access control system",
    img2: `${cloudFrontOrigin}proximity-card-transmits.jpg`,
    img3: `${cloudFrontOrigin}proximity-card-advantages.jpg`,
    img4: `${cloudFrontOrigin}proximity-card-disadvantages.jpg`,
    imgSrcCaption: "proximity card image",
    imgSrcAlt: "An image of a proximity card which is a type of contactless cards.",
    img2Caption: "proximity card transmits image",
    img2Alt: " A proximity card transmiting a low-level fixed radio frequency signals.",
    img3Caption: "proximity card readers advantages",
    img3Alt:
      "Advantages of using proximity card reader in an access control solutions explained in infographic.",
    img4Caption: "proximity card readers disadvantages",
    img4Alt:
      "Disadvantages of using proximity card reader in an access control solutions explained in infographic.",
  },
  {
    imgSrc: `${cloudFrontOrigin}biometric-scanner-access-system.jpg`,
    date: "JAN 2021",
    template: Jan1,
    title: "Biometric Scanner- Types, Categories, Usage In Access Control, & More",
    subTopic: "Biometric Scanner - Types & its Usage in Access Control & More",
    pillarTopic: "Card Access Control System",
    url: "/blog/biometric-scanner-access-control-system",
    metaTitle: "Biometric Scanner - Types & its Usage in Access Control & More",
    metaDescription:
      "Biometric Scanner | Biometric access control scanners - Learn about the biometric scanners & how industries are using biometric scanners for their safety.",
    metaUrl: "biometric-scanner-access-control-system",
    metaKeywords:
      "biometric access solution, biometric access card, fingerprint access syste, biometric access control system, types of biometric access control",
    focusKeywords: "biometric scanner access control system",
    img2: `${cloudFrontOrigin}physiological-biometrics.jpg`,
    img3: `${cloudFrontOrigin}behavioral-biometrics.jpg`,
    img4: `${cloudFrontOrigin}biometric-thumbprint-scanning.jpg`,
    imgSrcCaption: "biometric scanner access system image",
    imgSrcAlt: "An image of a biometric scanner on a wall for access control.",
    img2Caption: "physiological biometrics images",
    img2Alt:
      "Different types of physiological biometrics are face identification, hand identification, fingerprint identification, iriis identification.",
    img3Caption: "behavioral biometrics image",
    img3Alt:
      "Different types of behavioral biometrics are keystroke identification, voice identification and signature identification.",
    img4Caption: "biometric thumbprint scanning for access control",
    img4Alt: "Image showing biometric scanning with thumbprints as an access control system. ",
  },
  {
    imgSrc: `${cloudFrontOrigin}laser-barcode-reader.jpg`,
    template: Dec3,
    date: "DEC 2020",
    title: "Barcode Acccess Control System - Barcode Reader, Its Typers & Usage",
    subTopic: "Barcode Readers | Types of Barcode Readers & its Usage",
    pillarTopic: "Card Access Control System",
    url: "/blog/barcode-reader-access-control-system",
    metaTitle: "Barcode Readers | Types of Barcode Readers & its Usage",
    metaDescription:
      "Learn about the Barcode readers and their 7 different technologies, barcode scanners are now designed to offer increased security & safety!",
    metaUrl: "barcode-reader-access-control-system",
    metaKeywords:
      "card based access solution, barcode access card, barcode card entry, card access control system, types of card access control",
    focusKeywords: "barcode reader access control system",
    img2: `${cloudFrontOrigin}pen-type-barcode-reader.png`,
    img3: `${cloudFrontOrigin}laser-barcode-scanner.png`,
    img4: `${cloudFrontOrigin}ccd-readers.png`,
    img5: `${cloudFrontOrigin}camera-based-readers.png`,
    img6: `${cloudFrontOrigin}cell-phone-camera.png`,
    img7: `${cloudFrontOrigin}smartphone-qr-code-scan.png`,
    imgSrcCaption: "laser barcode reader image ",
    imgSrcAlt: "An image of a laser barcode reader used for scanning QR codes.",
    img2Caption: "pen type barcode reader image",
    img2Alt:
      "An image of a pen type barcode reader which is a pen-like instrument used to scan a barcode.",
    img3Alt: "A laser barcode scanner image with LED light and Photodiode.",
    img3Caption: "laser barcode scanner image",
    img4Alt: "An image of ccd readers also known as LED scanners to read and transmit information.",
    img4Caption: "ccd readers image",
    img5Alt: "An image of camera based readers used to decode information stored in a barcode.",
    img5Caption: "camera based readers image",
    img6Alt: "Cell phone camera with auto-focus being used to can QR code at SecurePass.",
    img6Caption: "cell phone camera image",
    img7Alt: "Smartphone camera supporting barcode or qr code scanning.",
    img7Caption: "smartphone qr code scan image",
  },
  {
    imgSrc: `${cloudFrontOrigin}barcode-cards.png`,
    template: Dec2,
    date: "DEC 2020",
    title: "Card-Based Access Control: Types of Cards & Evolution of Access Control",
    subTopic: "Card Based Access Control System | Evolution & types of Cards",
    pillarTopic: "Card Access Control System",
    url: "/blog/card-based-access-control-system",
    metaTitle: "Card Based Access Control System | Evolution & types of Cards",
    metaDescription:
      "Learn all about card based access control systems, types of cards access system like Barcode, Biometric, Magnetic Strip, Proximity Cards & more.",
    metaUrl: "card-based-access-control-system",
    metaKeywords: "card based access solution, card access control system, types of card access control",
    focusKeywords: "card based access control system",
    img2: `${cloudFrontOrigin}biometric-cards.png`,
    img3: `${cloudFrontOrigin}magnetic-strip-cards.png`,
    img4: `${cloudFrontOrigin}wiegand-cards.png`,
    img5: `${cloudFrontOrigin}proximity-cards.png`,
    imgSrcAlt: "A barcode card is plastic card that comes with a scannable printed barcode.",
    imgSrcCaption: "barcode cards image",
    img2Alt:
      "Biometric cards used for access control systems are based on the physical and behavioural traits.",
    img2Caption: "biometric cards image",
    img3Alt: "An image showing magnetic strip cards that is embedded with a code to identify the user.",
    img3Caption: "magnetic strip cards image",
    img4Alt:
      "An image showing wiegand cards that works by inserting it inside an access control device.",
    img4Caption: "wiegand cards image",
    img5Alt:
      "An image of proximity card that works by bringing the card near to an access control system, within a specified range.",
    img5Caption: "proximity cards image",
  },
  {
    imgSrc: `${cloudFrontOrigin}atm-pin-technology.jpg`,
    template: Dec1,
    date: "DEC 2020",
    title: "Evolution of Access Control Systems: PIN as Earliest Technology Intervention",
    pillarTopic: "Access Control System",
    url: "/blog/access-control-system-evolution",
    metaTitle: "Evolution of Access Control System ",
    metaDescription:
      "Learn about the evolution of access control system, advantages & disadvantage of PIN locking system",
    metaUrl: "access-control-system-evolution",
    metaKeywords:
      "access control system, access control solution, contactless access control system, access solution for office, access control system history",
    focusKeywords: "access control system history",
    img2: `${cloudFrontOrigin}lock-key-security.jpg`,
    img3: `${cloudFrontOrigin}hacker-breaching-security.jpg`,
    imgSrcAlt: "An image showing pin technology used in ATM (automated teller machine).",
    imgSrcCaption: "atm pin technology image",
    img2Alt: "Lock and key used as earliest security measure to secure the entrance of any premise.",
    img2Caption: "lock key security image",
    img3Alt: "A hacker using dark web to breach into access control system with pin.",
    img3Caption: "hacker breaching security image",
  },
  {
    imgSrc: `${cloudFrontOrigin}cloud-based-technology.png`,
    template: BlogcloudAccessManagement,
    date: "NOV 2020",
    title: "5 Benefits of using Cloud Access Management System",
    url: "/blog/cloud-access-management-system-benefits",
    metaTitle: "Cloud Access Management System Benefits",
    subTopic: "Cloud Access Management System",
    pillarTopic: "Best Contactless Access Control System",
    metaDescription:
      "Learn benefits of Cloud Access Management System for more security of your premises with advanced access management features",
    metaUrl: "cloud-access-management-system-benefits",
    metaKeywords:
      "contactless access management system, cloud access management system, centralised access control system",
    focusKeywords: "Cloud Access Management System",
    img2: `${cloudFrontOrigin}cloud-based-access-system.png`,
    img3: `${cloudFrontOrigin}securepass-dashboard.png`,
    imgSrcAlt: "An image of cloud based technology being used in access control solutions.",
    imgSrcCaption: "cloud based technology image",
    img2Alt: "An image of cloud based access system used to unlock the main door.",
    img2Caption: "cloud based access system image",
    img3Alt: "Image of SecurePass mac phone access control system.",
    img3Caption: "securepass mac phone access control system image",
  },
  {
    imgSrc: `${cloudFrontOrigin}classroom-sanitisation.jpg`,
    template: BlogEducationalInstitutions,
    date: "NOV 2020",
    title:
      "How Educational Institutions could use Contactless Access Management System to ensure Safety of Students and Staff",
    url: "/blog/educational-institution-safety",
    subTopic: "Educational Institution Safety",
    pillarTopic: "Contactless access and safety",
    metaTitle: "Safe Contactless Access Management System for Educational Institutions ",
    metaDescription:
      "Contactless Access Management System for Educational Institutions - Ensure Safety of your Students and Staff during the COVID Pandemic",
    metaUrl: "educational-institution-safety",
    metaKeywords:
      "educational institution safety, contactless safety for educational institutions, contactless entry, Students and staff safety",
    focusKeywords: "educational institution safety",
    img2: `${cloudFrontOrigin}contactless-temperature-screening.png`,
    img3: `${cloudFrontOrigin}touchless-access-attendance.png`,
    imgSrcAlt: "Image showing a women sanitising classroom.",
    imgSrcCaption: "classroom sanitisation image",
    img2Alt: "Contactless temperature screening device is a must have in school premises.",
    img2Caption: "contactless temperature screening image",
    img3Alt:
      "Student id cards used for touchless entrance at educational institutes and coaching centres.",
    img3Caption: "touchless access attendance image",
  },
  {
    imgSrc: `${cloudFrontOrigin}barsa-durga-pooja.jpg`,
    template: BlogDurgaPuja,
    date: "OCT 2020",
    title: "Contactless Access and Safety for Durga Pooja Celebration",
    pillarTopic: "Contactless access and safety",
    subTopic: "Contactless access for Durga pooja",
    url: "/blog/contactless-access-durga-pooja-celebration",
    metaTitle: "Contactless Access To Durga Pooja Celebration ",
    metaDescription:
      "Barsa Durga Puja organises a 5 day long Durga Puja celebration in HSR Layout, Bangalore. All precautions and safety regulations in place with SecurePass.",
    metaUrl: "contactless-access-durga-pooja-celebration",
    metaKeywords:
      "contactless access for public events, contactless access for events, contactless access for durga pooja, Contactless Access Durga Pooja Celebration ",
    focusKeywords: "Contactless Access Durga Pooja Celebration ",
    img2: `${cloudFrontOrigin}durga-pooja-registration.jpg`,
    imgSrcAlt: "A photograph from Barsa Durga pooja celebrations showing Hindu Goddess Durga.",
    imgSrcCaption: "barsa durga pooja photograph",
    img2Alt: "A display of registration instructions for Brasa durga pooja celebrations in Bangalore.",
    img2Caption: "image displaying durga pooja registration",
  },
  {
    imgSrc: `${cloudFrontOrigin}coworking-space-security.jpg`,
    date: "OCT 2020",
    template: BlogaccessManagementControlSystemForCoWorkingSpace,
    title: "How To Choose The Best Contactless Access Control System For Co-working Space",
    pillarTopic: "Best Contactless Access Control System",
    subTopic: "AMS for co-working space",
    url: "/blog/contactless-access-control-system-coworking-space",
    metaTitle: "Contactless Access Control System for Coworking Space",
    metaDescription:
      "Contactless Access Control System for Coworking Space: Explore access management system to ensure social distancing and safety of employees",
    metaUrl: "contactless-access-control-system-coworking-space",
    metaKeywords:
      "contactless access management system coworking space, contactless access control coworking space, access management system for coworking space, coworking space contactless access control system, Contactless Access Control System Coworking Space",
    focusKeywords: "Contactless Access Control System Coworking Space",
    img2: `${cloudFrontOrigin}automated-access-system.jpg`,
    img3: `${cloudFrontOrigin}coworking-access-system.jpg`,
    img4: `${cloudFrontOrigin}coworking-access-illustration.jpg`,
    img5: `${cloudFrontOrigin}fingerprint-lock-illustration.jpg`,
    imgSrcAlt: "Image related to coworking space security using access control solutions.",
    imgSrcCaption: "coworking space security image",
    img2Alt:
      "Image showing benefits of automated access system that is enhanced security, 24*7 accessibility and more.",
    img2Caption: "automated access system image",
    img3Alt: "An infographic showing access management system for a coworking space.",
    img3Caption: "coworking access system image",
    img4Alt: "Illustration for access control system working in a coworking space.",
    img4Caption: "coworking access control system illustration",
    img5Alt: "An illustration showing fingerprint lock working for better security.",
    img5Caption: "fingerprint lock illustration",
  },
  {
    imgSrc: `${cloudFrontOrigin}restaurant-illustration.jpg`,
    template: BlogaccessManagementControlSystemForRestaurants,
    date: "OCT 2020",
    title: "How To Choose The Best Contactless Access Control System For Your Restaurant",
    pillarTopic: "Best Contactless Access Control System",
    subTopic: "AMS for resturants",
    url: "/blog/contactless-access-control-system-restaurants",
    metaTitle: "Contactless Access Control System for Restaurants",
    metaDescription:
      "Contactless Access Control System for Restaurants: Explore access management system for resturants that ensure safety for guests and business",
    metaUrl: "contactless-access-control-system-restaurants",
    metaKeywords:
      "contactless access management system restaurants, contactless access control restaurants, access management system for restaurants, restaurants contactless access control system, Contactless Access Control System Restaurants",
    focusKeywords: "Contactless Access Control System Restaurants",
    imgSrcAlt: "Illustration of access control management system and its working in restaurants.",
    imgSrcCaption: "restaurant illustration random",
  },
  {
    imgSrc: `${cloudFrontOrigin}business-reopen-notice.jpg`,
    date: "OCT 2020",
    template: BlogaccessManagementControlSystemForRetailStores,
    title: "How Can Retailers Ensure Customer Safety At Their Retail Stores",
    pillarTopic: "Contactless access and safety",
    subTopic: "Customer safety at retail stores",
    url: "/blog/customer-safety-retail-stores",
    metaTitle: "How To Ensure Customer Safety At Their Retail Stores",
    metaDescription:
      "Explore modern access management control system for retail store that lets you manage access to your property from a centralized location",
    metaUrl: "customer-safety-retail-stores",
    metaKeywords:
      "retail store access control system, customer safety for retail store, customer safety for retailers, Customer Safety Retail Stores",
    focusKeywords: "Customer Safety Retail Stores",
    imgSrcAlt: "An image of a man pasting a notice of open business as new normal on the entry door.",
    imgSrcCaption: "business reopen notice image",
  },
  {
    imgSrc: `${cloudFrontOrigin}otp-based-login.jpg`,
    date: "OCT 2020",
    template: BlogaccessManagementControlSystemForSME,
    title: "How To Choose The Best Contactless Access Control System For SME or A Startup",
    pillarTopic: "Best Contactless Access Control System",
    subTopic: "AMS for SME or Startups",
    url: "/blog/contactless-access-control-system-sme-startup",
    metaTitle: "Contactless Access Control System For A SME / Startup",
    metaDescription:
      "Contactless Access Control System for SME / Startup: Explore best access management control system for startups and SME's with cost and time effective",
    metaUrl: "contactless-access-control-system-sme-startup",
    metaKeywords:
      "contactless access management system sme startup, contactless access control sme startup, access management system for sme startup, sme startup contactless access control system, Contactless Access Control System SME Startup",
    focusKeywords: "Contactless Access Control System SME Startup",
    img2: `${cloudFrontOrigin}vms-illustration.jpg`,
    imgSrcAlt: "Image showing a man doing otp based login on a desktop to access the system.",
    imgSrcCaption: "otp based login image",
    img2Alt: "Illustration showing an in-built visitor management system to record details of visitors.",
    img2Caption: "vms illustration",
  },
  {
    imgSrc: `${cloudFrontOrigin}hospital-access-system.jpg`,
    date: "OCT 2020",
    template: BlogaccessManagementControlSystemForHospitals,
    title: "How To Choose The Best Contactless Access Control System For Hospitals",
    pillarTopic: "Best Contactless Access Control System",
    subTopic: "AMS for Hospitals",
    metaTitle: "Contactless Access Control System for Hospitals",
    metaDescription:
      "Contactless Access Control System for Hospitals: Explore the access management control system that can be customised for hospitals in times of COVID19",
    url: "/blog/contactless-access-control-system-hospitals",
    metaKeywords:
      "contactless access management system hospital, contactless access control hospital, access management system for hospital, hospital contactless access control system, Contactless Access Control System Hospitals",
    focusKeywords: "Contactless Access Control System Hospitals",
    img2: `${cloudFrontOrigin}smart-card-access.jpg`,
    img3: `${cloudFrontOrigin}ai-illustration.jpg`,
    imgSrcAlt:
      "An illustration defining the working of a contactless access control system in a hospital.",
    imgSrcCaption: "hospital access control system illustration",
    img2Alt: "Image of an smart card used for contactless access in a hospital.",
    img2Caption: "smart card access control system",
    img3Alt: "An image displaying the Artificial Intelligence technology used in hospital.",
    img3Caption: "ai technology illustration",
  },
  {
    imgSrc: `${cloudFrontOrigin}mobile-vms.jpg`,
    date: "OCT 2020",
    template: BlogVisitorManagementChecklist,
    title: "Visitor Management System To Ensure Safety Compliance ",
    pillarTopic: "Visitor Management System",
    subTopic: "Visitor Management Checklists",
    url: "/blog/visitor-management-system-ensure-safety",
    metaTitle: "Visitor Management System To Ensure Safety Compliance ",
    metaDescription:
      "Explore contactless visitor management system that allows businesses to comply with the safety protocols from the spread of COVID19",
    metaUrl: "visitor-management-system-ensure-safety",
    metaKeywords:
      "visitor management system to ensure safety, visitor management system checklists, vms, contactless visitor management system, Visitor Management System Ensure Safety",
    focusKeywords: "Visitor Management System Ensure Safety",
    img2: `${cloudFrontOrigin}office-social-distancing.jpg`,
    img3: `${cloudFrontOrigin}vms-stock-image.jpg`,
    imgSrcAlt:
      "An illustration of visitor management system based on OTP for contactless access via mobile.",
    imgSrcCaption: "mobile vms illustration",
    img2Alt: "Employees maintaining the 6-feet distance for social distancing at office.",
    img2Caption: "office social distancing illustration",
    img3Alt: "A stock image of visitor management system.",
    img3Caption: "visitor management system stock image",
  },
  {
    imgSrc: `${cloudFrontOrigin}retail-social-distancing.jpg`,
    template: Blog5BreakthroughWaysToEnsure,
    date: "SEP 2020",
    title: "5 Breakthrough Ways To Implement Social Distancing At Retail Stores",
    url: "/blog/implement-social-distancing-retail-stores",
    metaTitle: "5 Ways To Implement Social Distancing At Retail Stores",
    pillarTopic: "Social Distancing",
    subTopic: "Implement social distancing for retail stores",
    metaDescription:
      "Learn 5 simple ways to implement social distancing at retail stores for making it extra “Safe” for your customers",
    metaUrl: "implement-social-distancing-retail-stores",
    metaKeywords:
      "social distancing for retailers, social distancing for retail store, retail store social distancing ideas, Implement Social Distancing Retail Stores",
    focusKeywords: "Implement Social Distancing Retail Stores",
    img2: `${cloudFrontOrigin}queue-cross-mark.jpg`,
    img3: `${cloudFrontOrigin}floor-marking.jpg`,
    img4: `${cloudFrontOrigin}curbside-pickups.jpg`,
    img5: `${cloudFrontOrigin}traffic-detection-sensors.jpg`,
    img6: `${cloudFrontOrigin}restricted-entry.jpg`,
    imgSrcAlt: "Image showing 5 breakthrough ways to implement Social Distancing at retail stores.",
    imgSrcCaption: "retail social distancing image",
    img2Alt: "An image of cross marks for queue positions for social distancing at retail stores.",
    img2Caption: "queue cross mark image",
    img3Alt: "Image of an smart card used for contactless access in a hospital.",
    img3Caption: "store floor marking image",
    img4Alt: "Image of an smart card used for contactless access in a hospital.",
    img4Caption: "curbside pickups image",
    img5Alt: "Usage of sensors for traffic detection and crowd management to ensure social distancing.",
    img5Caption: "traffic detection sensors image",
    img6Alt:
      "Reduce density with standees aisle trespassers or aisle violators and real time updates of entrants.",
    img6Caption: "restricted entry to reduce density",
  },
  {
    imgSrc: `${cloudFrontOrigin}apartment-safety-covid-times.jpg`,
    template: BlogEightWaysToEnsureSafety,
    date: "SEP 2020",
    title: "8 Ways To Ensure Safety In Your Apartment Complex During COVID Pandemic",
    pillarTopic: "Contactless access and safety",
    subTopic: "Apartment Complex Safety",
    url: "/blog/residents-safety-apartments",
    metaTitle: "8 Ways To Ensure Safety In Apartment Complex During COVID",
    metaDescription:
      "Contactless access control system for residents of apartment complex - Ensure the safety of your residents at apartment complex during the COVID Pandemic",
    metaUrl: "residents-safety-apartments",
    metaKeywords:
      "residents safety apartments, contactless safety for apartment residents, apartment residents safety contactless entry, Residents Safety Apartments",
    focusKeywords: "Residents Safety Apartments",
    imgSrcAlt: "Illustration of appartment safety during covide times",
    imgSrcCaption: "apartment safety covid times image",
  },
  {
    imgSrc: `${cloudFrontOrigin}business-social-distancing.jpg`,
    template: BlogGovernmentGuidlines,
    date: "SEP 2020",
    title: "How To Practise Social Distancing For Business",
    pillarTopic: "Social Distancing",
    subTopic: "Practise social distancing for business",
    url: "/blog/practise-social-distancing-business",
    metaTitle: "How To Practise Social Distancing For Business",
    metaDescription:
      "Learn how to practise social distancing and safety for your business everyday to assist owners, staff, or to mitigate the impact of COVID19.",
    metaUrl: "practise-social-distancing-business",
    metaKeywords:
      "contactless social distancing, social distancing business, practise social distancing retail stores, Practise Social Distancing Business",
    focusKeywords: "Practise Social Distancing Business",
    img2: `${cloudFrontOrigin}govt-covid-guidelines.jpg`,
    img3: `${cloudFrontOrigin}follow-social-distancing.jpg`,
    img4: `${cloudFrontOrigin}in-this-together.jpg`,
    img5: `${cloudFrontOrigin}beat-covid19.jpg`,
    imgSrcAlt: "Infographic showing must-follow practices for social distancing in a business.",
    imgSrcCaption: "business social distancing image",
    img2Alt: "Infographic showing must-follow practices for social distancing during COVID-19 by govt.",
    img2Caption: "govt covid guidelines infographic",
    img3Alt: "An image by SecurePass to be smart be safe and follow social distancing.",
    img3Caption: "follow social distancing infographic",
    img4Alt: "We are in this together, fight against COVID-19. An image by SecurePass",
    img4Caption: "in this together image",
    img5Alt: "An infographic with tips to beat COVID-19 by SecurePass.",
    img5Caption: "beat covid20 infographic",
  },
  {
    imgSrc: `${cloudFrontOrigin}retail-store-social-distancing.jpg`,
    template: BlogRulesAndGuidlinesForRetailers,
    date: "SEP 2020",
    title: "Government Guidelines For Social Distancing At Retail Stores",
    pillarTopic: "Social Distancing",
    subTopic: "Govt guidelines for social distancing for retailers",
    url: "/blog/government-guidelines-social-distancing-retail-stores",
    metaUrl: "government-guidelines-social-distancing-retail-stores",
    metaTitle: "Government Guidelines For Social Distancing At Retail Stores",
    metaDescription:
      "Learn about all the important social distancing guidelines provided by the government for businesses in the retail industry.",
    metaKeywords:
      "social distancing government guidelines, retail store social distancing guidelines, contactless social distancing retail stores, Government Guidelines Social Distancing Retail Stores",
    focusKeywords: "Government Guidelines Social Distancing Retail Stores",
    img2: `${cloudFrontOrigin}floor-markers-stickers.jpg`,
    img3: `${cloudFrontOrigin}reta1dilerguideline3_pvsjvf_ufgqeh.jpg`,
    imgSrcAlt: "Infographic saying apply social distancing to your retail stores.",
    imgSrcCaption: "retail store social distancing infographic",
    img2Alt: "Illustration displaying floor markers or stickers used for social distancing practices.",
    img2Caption: "floor markers stickers image",
    img3Alt: "",
    img3Caption: "",
  },
  // add newest blog to the 0th index
];
